import CheckIcon from '@mui/icons-material/Check';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import { commaizeNumber } from '@wooriga/common-utils';
import {
  Box,
  Button,
  DataGrid,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  TextField,
  Typography,
} from '@wooriga/design-system';
import * as _ from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  ElectronicConsentCreateBody,
  useCreateElectronicConsent,
} from 'apis/electronic-consents/apis';
import { ELECTRONIC_CONSENT_TARGET_COLUMNS } from 'apis/electronic-consents/fixture';
import { useUnionRegistersQuery } from 'apis/union/register/apis';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useFeedback from 'hooks/useFeedback';
import IndexTemplate from 'templates/IndexTemplate';
import { CustomRouteObject } from 'types/route';
import { generateRandomString } from 'utils/byte';

const DEFAULT_SEARCH_PARAMS = {
  name: '',
  shareType: '',
  ownerType: '',
};

const ConsentRecipientPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();

  const queryClient = useQueryClient();

  const unionSeq = Number(params.unionSeq);

  const { getGroupCode } = useCommonCodes();
  const { alertDialog, confirmDialog, snackbar } = useFeedback();

  const [searchParams, setSearchParams] = useState<
    typeof DEFAULT_SEARCH_PARAMS
  >(DEFAULT_SEARCH_PARAMS);
  const [openUnionRegisterDetailModal, setOpenUnionRegisterDetailModal] =
    useState<boolean>(false);

  const [selectedValues, setSelectedValues] = useState<
    Pick<typeof DEFAULT_SEARCH_PARAMS, 'shareType' | 'ownerType'>
  >({
    shareType: '',
    ownerType: '',
  });

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [selectUnionRegisterSeq, setSelectUnionRegisterSeq] = useState<
    number | undefined
  >(undefined);

  const {
    data: unionRegisters,
    isLoading,
    isError,
    error,
  } = useUnionRegistersQuery(unionSeq, searchParams);

  const { mutate: createElectronicConsent } = useCreateElectronicConsent();

  const handleSearchParams = (
    values: Partial<typeof DEFAULT_SEARCH_PARAMS>,
  ) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...values,
      ...selectedValues,
    }));
  };

  const handleSearchReset = () => {
    setSelectedValues({ shareType: '', ownerType: '' });
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };

  const handleRowSelectedModelChange = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      setRowSelectionModel(rowSelectionModel);
    },
    [],
  );

  const handleConfirmAlertDialog = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['/electronic-consent-forms'],
    });
    navigate(`/unions/${unionSeq}/consent-management`, {
      replace: true,
    });
  }, [navigate, queryClient, unionSeq]);

  const handleSubmit = useCallback(() => {
    const removedState = _.omit(
      state,
      'isEndDateUnknown',
    ) as ElectronicConsentCreateBody;

    const linkCode = generateRandomString(6);

    confirmDialog('작성된 내용으로 등록되며, 시작일시에 자동 발송됩니다.', {
      message: '전자 동의서를 등록하시겠습니까?',
      onConfirm: () => {
        createElectronicConsent(
          {
            ...removedState,
            unionSeq,
            linkCode,
            unionRegisterSeqs: rowSelectionModel as number[],
          },
          {
            onSuccess: () => {
              alertDialog('전자 동의서 상세 페이지에서 현황을 확인하세요.', {
                message: '전자 동의서가 등록되었습니다.',
                onSubmit: handleConfirmAlertDialog,
              });
            },
            onError: (error) => {
              snackbar(error?.response?.data.message ?? error.message, {
                color: 'danger',
              });
            },
          },
        );
      },
    });
  }, [
    unionSeq,
    confirmDialog,
    rowSelectionModel,
    state,
    alertDialog,
    snackbar,
    createElectronicConsent,
    handleConfirmAlertDialog,
  ]);

  const handleClickName = useCallback((unionRegisterSeq: number) => {
    setSelectUnionRegisterSeq(unionRegisterSeq);
    setOpenUnionRegisterDetailModal(true);
  }, []);

  const handleBack = useCallback(() => {
    navigate('..', { state });
  }, [navigate, state]);

  const rows = useMemo(() => unionRegisters?.data || [], [unionRegisters]);
  const { columns } = useCreateGridColumns(ELECTRONIC_CONSENT_TARGET_COLUMNS, {
    onClickName: handleClickName,
  });

  const { totalElements, totalDataCount } = useMemo(
    () => unionRegisters?.pagination ?? { totalElements: 0, totalDataCount: 0 },
    [unionRegisters?.pagination],
  );

  const shareTypeCodeGroup = useMemo(
    () => getGroupCode('UNION_REGISTER_SHARE_TYPE'),
    [getGroupCode],
  );
  const ownerTypeCodeGroup = useMemo(
    () => getGroupCode('UNION_REGISTER_USER_DIVIDE'),
    [getGroupCode],
  );

  if (isError) throw error;

  return (
    <Stack gap={3}>
      <Box width={360} sx={{ alignSelf: 'flex-end' }}>
        <Stepper>
          <Step
            indicator={
              <StepIndicator variant="solid" color="primary">
                <CheckIcon />
              </StepIndicator>
            }
          >
            전자 동의서 정보 입력
          </Step>
          <Step
            indicator={
              <StepIndicator variant="solid" color="primary">
                2
              </StepIndicator>
            }
          >
            대상자 선택
          </Step>
        </Stepper>
      </Box>

      <IndexTemplate
        SearchComponent={
          <Search
            defaultValues={DEFAULT_SEARCH_PARAMS}
            onSubmit={handleSearchParams}
            onReset={handleSearchReset}
          >
            <Grid container gap={2}>
              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    label="이름"
                    name="name"
                    placeholder="이름 검색"
                    validateOptions={{
                      maxLength: 30,
                    }}
                    fullWidth
                  />
                </Search.Field>
              </Grid>

              <Search.Field>
                <Grid xs={12} maxWidth={200}>
                  <FormControl sx={{ width: '100%' }}>
                    <FormLabel>소유구분</FormLabel>

                    <Select
                      name="shareType"
                      value={selectedValues.shareType}
                      onChange={(_, value) =>
                        setSelectedValues((prevValues) => ({
                          ...prevValues,
                          shareType: value as unknown as string,
                        }))
                      }
                    >
                      <Option value="">전체</Option>

                      {shareTypeCodeGroup?.items.map(({ code, name }) => (
                        <Option key={`share_type_${code}`} value={code}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Search.Field>

              <Search.Field>
                <Grid xs={12} maxWidth={200}>
                  <FormControl sx={{ width: '100%' }}>
                    <FormLabel>개인/법인</FormLabel>

                    <Select
                      name="ownerType"
                      value={selectedValues.ownerType}
                      onChange={(_, value) =>
                        setSelectedValues((prevValues) => ({
                          ...prevValues,
                          ownerType: value as unknown as string,
                        }))
                      }
                    >
                      <Option value="">전체</Option>

                      {ownerTypeCodeGroup?.items.map(({ code, name }) => (
                        <Option key={`owner_type_${code}`} value={code}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Search.Field>
            </Grid>
          </Search>
        }
        TopPanelComponent={
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(totalDataCount)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(totalElements)}
              </Typography>
            </Typography>
          </Stack>
        }
        IndexComponent={
          <Stack height={442}>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={isLoading}
              rowSelectionModel={rowSelectionModel}
              getRowId={(row) => row.unionRegisterSeq}
              columnBufferPx={Infinity}
              getRowHeight={(params) =>
                (params?.model?.localAddresses?.length || 1) * 40 || 40
              }
              onRowSelectionModelChange={handleRowSelectedModelChange}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Stack>
        }
        BottomPanelComponent={
          <Stack direction="row" justifyContent="space-between">
            <Button variant="outlined" color="neutral" onClick={handleBack}>
              이전
            </Button>

            <Button
              disabled={rowSelectionModel.length === 0}
              onClick={handleSubmit}
            >
              생성
            </Button>
          </Stack>
        }
      />

      {selectUnionRegisterSeq && openUnionRegisterDetailModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq: selectUnionRegisterSeq }}
          open={openUnionRegisterDetailModal}
          onClose={setOpenUnionRegisterDetailModal}
        />
      )}
    </Stack>
  );
};

const route: CustomRouteObject = {
  path: '',
  index: true,
  element: <ConsentRecipientPage />,
  handle: {
    getTitle: () => '동의서 대상자 선택',
  },
};

const electronicConsentInfo = {
  path: 'selection',
  children: [route],
  handle: {
    getTitle: () => '동의서 대상자 선택',
  },
};

export default electronicConsentInfo;
