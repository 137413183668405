import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { UnionRegisterInfo } from 'apis/types/union';
import { ApiError, ApiResponseData } from 'types/api';

// 일반 동의서 파트
export interface ConsentsRegisterSearchParams {
  unionSeq: number;
  unionRegisterNo: string;
  name: string;
  phoneNo: string;
  localAddress: string;
  shareType: string;
  searchFrom: string;
  searchTo: string;
}
export interface ConsentRegisterParams {
  unionSeq: number;
}
export interface ConsentRegisterBody {
  unionRegisterSeqs: number[];
  responseAt?: string;
  consentStatus: string;
}

export interface ConsentsParams {
  unionSeq: number;
  unionRegisterNo?: string;
  name?: string;
  phoneNo?: string;
  localAddress?: string;
  shareType?: string;
  searchFrom?: string;
  searchTo?: string;
}

export type ConsentsRegisterReturnData = UnionRegisterInfo[];
export const useConsentsQuery = (
  params: ConsentsParams,
): UseQueryResult<ApiResponseData<ConsentsRegisterReturnData>, ApiError> => {
  const { unionSeq, ...restParams } = params;

  return useQuery({
    queryKey: [`/v1/unions/${unionSeq}/consents/registers`, restParams],
  });
};

export type RegisterConsentRequest = ConsentRegisterParams &
  ConsentRegisterBody;

export const useRegisterConsentMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  RegisterConsentRequest
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, ...body } = request;
      const { data } = await axiosInstance.post(
        `/v1/unions/${unionSeq}/consents`,
        body,
      );

      return data;
    },
  });
