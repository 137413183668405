import {
  Box,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from '@wooriga/design-system';

import WoorigaLogoChart from 'components/pages/consent-management/history/WoorigaLogoChart';

export interface ConsentAreaChartPanelProps {
  open: boolean;
  data: {
    title: string;
    label: string;
    percentage: number;
  };
  isLoading?: boolean;
  onChangeOpen: (open: boolean) => void;
}

const ConsentAreaChartPanel = ({
  open,
  isLoading,
  data,
  onChangeOpen,
}: ConsentAreaChartPanelProps) => {
  const { title, label, percentage } = data;

  const heightOptions = open ? { height: '100%' } : null;

  return (
    <Card sx={{ px: 5, py: 3, ...heightOptions }}>
      <CardContent sx={{ gap: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            fontSize="md"
            fontWeight="lg"
            lineHeight="md"
            textColor="neutral.500"
          >
            면적 기준 동의율
          </Typography>
          <Switch
            checked={open}
            onChange={(event) => onChangeOpen(event.target.checked)}
          />
        </Stack>

        {open && (
          <Stack direction="row" justifyContent="space-between" flex={1}>
            <Stack gap={1}>
              <Skeleton loading={isLoading} variant="rectangular">
                <Typography fontSize="xl3" fontWeight="xl" lineHeight="xs">
                  {title}
                </Typography>
              </Skeleton>

              <Skeleton loading={isLoading} variant="rectangular">
                <Typography
                  fontSize="md"
                  fontWeight="md"
                  lineHeight="md"
                  textColor="neutral.500"
                >
                  {label}
                </Typography>
              </Skeleton>
            </Stack>

            <Box my="auto">
              <WoorigaLogoChart value={percentage} />
            </Box>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default ConsentAreaChartPanel;
