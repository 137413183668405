import { Chip, ChipProps } from '@wooriga/design-system';

import CommonCode, { CommonCodeProps } from 'components/CommonCode';

export interface CertificateIssuanceStatusChipProps
  extends Pick<CommonCodeProps, 'code'> {
  size?: ChipProps['size'];
}

const CertificateIssuanceStatusChip = ({
  code,
  size,
}: CertificateIssuanceStatusChipProps) => {
  const getVariant = (): {
    variant?: ChipProps['variant'];
    color?: ChipProps['color'];
  } => {
    switch (code) {
      case 'UNAVAILABLE':
        return {
          color: 'neutral',
        };
      case 'PENDING':
        return {
          color: 'warning',
        };
      case 'AVAILABLE':
        return {
          color: 'blue',
        };
      case 'DOWNLOAD':
        return { color: 'success' };

      default:
        return {};
    }
  };

  return (
    <Chip {...getVariant()} size={size ?? 'md'}>
      <CommonCode groupCode="CERTIFICATE_ISSUED_STATUS" code={code} />
    </Chip>
  );
};

export default CertificateIssuanceStatusChip;
