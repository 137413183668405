import InfoIcon from '@mui/icons-material/Info';
import { commaizeNumber } from '@wooriga/common-utils';
import { Callout, Grid, Typography } from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';

import {
  useReadElectronicConsent,
  useReadElectronicConsentStatisticsArea,
  useReadElectronicConsentStatisticsDong,
} from 'apis/electronic-consents/apis';
import ConsentInfoModal from 'components/pages/consent-management/history/modals/ConsentInfoModal';
import ConsentPreviewModal from 'components/pages/consent-management/history/modals/ConsentPreviewModal';
import ConsentAreaChartPanel from 'components/pages/consent-management/history/panels/ConsentAreaChartPanel';
import ConsentDongChartPanel from 'components/pages/consent-management/history/panels/ConsentDongChartPanel';
import ConsentInfoPanel from 'components/pages/consent-management/history/panels/ConsentInfoPanel';
import ConsentTargetChartPanel from 'components/pages/consent-management/history/panels/ConsentTargetChartPanel';
import useFeedback from 'hooks/useFeedback';
import useMenuPanel from 'hooks/useMenuPanel';
import { MenuPanelName } from 'stores/menu-panel';

export interface DetailMenuTabProps {
  unionSeq: number;
  consentSeq: number;
}

const DetailMenuTab = ({ unionSeq, consentSeq }: DetailMenuTabProps) => {
  const { snackbar } = useFeedback();
  const { electronicConsent, activatePanel, deactivatePanel } = useMenuPanel();

  const { data: consentResponse, isLoading: isConsentLoading } =
    useReadElectronicConsent({ consentSeq });

  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);

  const { data: statisticsArea, isLoading: isAreaLoading } =
    useReadElectronicConsentStatisticsArea({
      consentSeq,
      isEnabled: electronicConsent.isAreaPanelOpen,
    });
  const { data: statisticsDong, isLoading: isDongLoading } =
    useReadElectronicConsentStatisticsDong({
      consentSeq,
      isEnabled: electronicConsent.isDongPanelOpen,
    });

  const handleChangePanelOpen = (name: MenuPanelName) => (value: boolean) => {
    if (!value) {
      deactivatePanel(name);
      return;
    }

    activatePanel(name);
  };

  const handleCopyElectronicConsentUrl = useCallback(async () => {
    if (!consentResponse?.data.consentUrl) {
      snackbar('복사할 전자 동의서 URL이 없습니다.', { color: 'danger' });
      return;
    }

    await navigator.clipboard.writeText(consentResponse?.data.consentUrl);

    snackbar('전자 동의서 URL이 복사되었습니다.');
  }, [consentResponse?.data, snackbar]);

  const { consent, submitted, total } = useMemo(
    () => ({
      consent: consentResponse?.data,
      submitted: consentResponse?.data.recipientCount.submitted ?? 0,
      total: consentResponse?.data.recipientCount.total ?? 0,
    }),
    [consentResponse?.data],
  );

  const area = useMemo(() => {
    const total = Math.floor(statisticsArea?.data.totalArea ?? 0);
    const consented = Math.floor(statisticsArea?.data.consentedArea ?? 0);
    const percentage = Math.ceil((consented / total) * 100 * 100) / 100 || 0;

    return {
      title: `${percentage}%`,
      label: `(${commaizeNumber(consented)}㎡ / ${commaizeNumber(total)}㎡)`,
      percentage,
    };
  }, [statisticsArea]);

  const dong = useMemo(
    () =>
      statisticsDong?.data.map(({ dong, totalCount, consentedCount }) => ({
        id: `${dong}`,
        title: `${dong}동`,
        value: Math.ceil((consentedCount / totalCount) * 100 * 100) / 100,
        label: `${consentedCount}/${totalCount}명`,
      })) ?? [],
    [statisticsDong],
  );

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Callout
          startDecorator={<InfoIcon sx={{ mt: 0.25, color: '#6E7979' }} />}
        >
          <Typography textColor="neutral.700">
            동의서 집계 결과는 참고용으로만 사용해 주세요.
          </Typography>
          <Typography textColor="neutral.500">
            집계 결과는 현재까지 징구된 동의서의 현황이며, 동의서 제출 및 검토,
            소유자 변동 상황에 따라 집계 결과가 차이날 수 있습니다.
            <br />
            동의서 제출 및 검토, 소유자 변동 상황에 따라 집계 결과가 차이날 수
            있습니다.
          </Typography>
        </Callout>
      </Grid>

      <Grid xs={12} lg={6}>
        <ConsentInfoPanel
          data={{ consent }}
          loading={isConsentLoading}
          onClickInfo={() => setIsInfoModalOpen(true)}
          onClickPreview={() => setIsPreviewModalOpen(true)}
          onClickClipboard={handleCopyElectronicConsentUrl}
        />
      </Grid>

      <Grid xs={12} lg={6}>
        <ConsentTargetChartPanel
          data={{ total, submitted }}
          loading={isConsentLoading}
        />
      </Grid>

      <Grid xs={12} lg={6}>
        <ConsentAreaChartPanel
          data={area}
          isLoading={isAreaLoading}
          open={electronicConsent.isAreaPanelOpen}
          onChangeOpen={handleChangePanelOpen('ELECTRONIC_CONSENT_AREA')}
        />
      </Grid>

      <Grid xs={12} lg={6}>
        <ConsentDongChartPanel
          data={dong}
          isLoading={isDongLoading}
          open={electronicConsent.isDongPanelOpen}
          onChangeOpen={handleChangePanelOpen('ELECTRONIC_CONSENT_DONG')}
        />
      </Grid>

      {isInfoModalOpen && (
        <ConsentInfoModal
          unionSeq={unionSeq}
          consentSeq={consentSeq}
          open={isInfoModalOpen}
          onClose={setIsInfoModalOpen}
        />
      )}

      {isPreviewModalOpen && consent?.template.fileUrl && (
        <ConsentPreviewModal
          url={consent.template.fileUrl}
          open={isPreviewModalOpen}
          onClose={setIsPreviewModalOpen}
        />
      )}
    </Grid>
  );
};

export default DetailMenuTab;
