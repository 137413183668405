import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import {
  Card,
  CardContent,
  Chip,
  Skeleton,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useMemo } from 'react';

import PieChartWrapper from 'components/PieChartWrapper';

export interface ConsentTargetChartPanelProps {
  data: {
    total: number;
    submitted: number;
  };
  loading?: boolean;
}

const ConsentTargetChartPanel = ({
  data,
  loading,
}: ConsentTargetChartPanelProps) => {
  const { total, submitted } = data;

  const { title, percentage, series } = useMemo(
    () => ({
      title: `${submitted} / ${total}`,
      percentage: `${Math.ceil((submitted / total) * 100 * 100) / 100}%`,
      series: [
        {
          innerRadius: 60,
          outerRadius: 75,
          data: [
            {
              id: 0,
              value: submitted,
              label: '동의서 제출 수',
              color: '#4393E4',
            },
            {
              id: 1,
              value: total - submitted,
              label: '동의서 미제출 수',
              color: '#E5E8E8',
            },
          ],
        },
      ],
    }),
    [total, submitted],
  );

  return (
    <Card sx={{ height: '100%', p: 5 }}>
      <CardContent
        sx={{ flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <Stack gap={2}>
          <Chip startDecorator={<SendOutlinedIcon />}>동의서 제출 현황</Chip>

          <Stack gap={1}>
            <Typography fontSize="xl3" fontWeight="xl" lineHeight="xs">
              <Skeleton
                loading={loading}
                variant="rectangular"
                width={140}
                height={40}
              >
                {title}
              </Skeleton>
            </Typography>
            <Typography
              fontSize="md"
              fontWeight="md"
              lineHeight="md"
              textColor="neutral.500"
            >
              (동의서 제출 수 / 대상자 수)
            </Typography>
          </Stack>
        </Stack>

        <Skeleton loading={loading} variant="circular" width={220}>
          <PieChartWrapper size="lg" series={series} title="" labelHidden>
            <PieChartWrapper.PercentLabel size="md" label={percentage} dy="0" />
          </PieChartWrapper>
        </Skeleton>
      </CardContent>
    </Card>
  );
};

export default ConsentTargetChartPanel;
