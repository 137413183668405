import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  TextField,
  Typography,
  ModalProps,
  KakaoPostcodeModal,
  Input,
  FormLabel,
  FormControl,
} from '@wooriga/design-system';
import { isObject } from 'lodash';
import { useEffect, useState } from 'react';
import { Address as KakaoAddress } from 'react-daum-postcode';
import { useForm } from 'react-hook-form';

import {
  useModifyUnionInfoMutation,
  useOfficeInfoQuery,
} from 'apis/union/info/apis';
import useFeedback from 'hooks/useFeedback';

interface UnionInfoModalProps {
  open: boolean;
  unionSeq: number;
  onClose: (value: boolean) => void;
  // onSubmit: (data: ModifyUnionInfoValues) => void;
  refetch: () => void;
}

interface UnionInfoValues {
  name: string;
  phoneNo: string;
  email: string;
  faxNo: string;
  managerName: string;
  managerPhoneNo?: string;
  address: Address;
}

interface Address {
  address: string;
  detailAddress: string;
  zipNo: string;
}

interface ModifyUnionInfoValues extends Omit<UnionInfoValues, 'address'> {
  address: string;
  detailAddress: string;
  zipNo: string;
  unionSeq: number;
}

const ModifyUnionInfoModal = (props: UnionInfoModalProps) => {
  const { mutate: modifyUnionInfo } = useModifyUnionInfoMutation();

  const { open, onClose, unionSeq, refetch: refetchOfficInfo } = props;
  const [isOpenKakao, setIsOpenKakao] = useState(false);

  const { data: OFFICE_DETAIL_DATA, refetch } = useOfficeInfoQuery({
    unionSeq,
  });

  const { snackbar, confirmDialog } = useFeedback();
  const {
    register,
    handleSubmit: validateSubmit,
    // reset,
    setValue,
  } = useForm<ModifyUnionInfoValues>();
  const handleComplete = (address: KakaoAddress) => {
    setValue('address', address.address || '');
    setValue('zipNo', address.zonecode || '');
  };

  const setForm = () => {
    if (OFFICE_DETAIL_DATA) {
      setValue('name', OFFICE_DETAIL_DATA?.data?.name || '');
      setValue('phoneNo', OFFICE_DETAIL_DATA?.data?.phoneNo || '');
      setValue('email', OFFICE_DETAIL_DATA?.data?.email || '');
      setValue('faxNo', OFFICE_DETAIL_DATA?.data?.faxNo || '');
      setValue('managerName', OFFICE_DETAIL_DATA?.data?.managerName || '');
      setValue(
        'managerPhoneNo',
        OFFICE_DETAIL_DATA?.data?.managerPhoneNo || '',
      );
      if (isObject(OFFICE_DETAIL_DATA?.data?.address)) {
        setValue('address', OFFICE_DETAIL_DATA?.data?.address.address || '');
        setValue(
          'detailAddress',
          OFFICE_DETAIL_DATA?.data?.address.detailAddress || '',
        );
        setValue('zipNo', OFFICE_DETAIL_DATA?.data?.address.zipNo || '');
      }
    }
  };
  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    refetch();
    onClose(false);
  };

  const handleSubmit = (data: ModifyUnionInfoValues) => {
    confirmDialog('등록하시겠습니까?', {
      onConfirm: () => {
        data.unionSeq = unionSeq;

        modifyUnionInfo(data, {
          onSuccess: () => {
            refetchOfficInfo && refetchOfficInfo();
            snackbar('등록에 성공하였습니다.', {
              color: 'success',
            });
          },
          onError: (error) => {
            snackbar(error.response?.data.message ?? error.message, {
              color: 'danger',
            });
          },
        });

        // reset();
        onClose(false);
      },
    });
  };

  useEffect(() => {
    setForm();
  }, [OFFICE_DETAIL_DATA, setValue]);

  return (
    <>
      <Modal
        component="form"
        open={open}
        onSubmit={validateSubmit(handleSubmit)}
        onClose={handleClose}
      >
        <Box>
          <ModalDialog
            sx={{
              width: '400px',
            }}
          >
            <DialogTitle>조합 사무실 수정</DialogTitle>
            <Typography level="body-md"></Typography>
            <DialogContent>
              <Stack gap={2}>
                <TextField
                  label="사무실 명"
                  placeholder="사무실 명 입력"
                  fullWidth
                  {...register('name', { required: '' })}
                />
                <Stack>
                  <FormControl>
                    <Stack
                      gap={1}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Box width="80%">
                        <FormLabel>사무실 주소</FormLabel>
                        <Input
                          fullWidth
                          startDecorator={<SearchIcon />}
                          placeholder="사무실 주소 입력"
                          disabled
                          {...register('address')}
                        />
                      </Box>
                      <Box display="flex" alignItems="flex-end">
                        <Button
                          color="neutral"
                          size="md"
                          variant="outlined"
                          onClick={() => setIsOpenKakao(true)}
                        >
                          검색
                        </Button>
                      </Box>
                    </Stack>
                  </FormControl>

                  <KakaoPostcodeModal
                    open={isOpenKakao}
                    postcodeEmbedProps={{ onComplete: handleComplete }}
                    onClose={() => {
                      setIsOpenKakao(false);
                    }}
                  />
                  <TextField
                    placeholder="상세 주소 입력"
                    fullWidth
                    {...register('detailAddress')}
                  />
                </Stack>

                <TextField
                  label="우편 번호"
                  placeholder="우편 번호 입력"
                  fullWidth
                  disabled
                  {...register('zipNo')}
                />
                <TextField
                  label="사무실 연락처"
                  placeholder="사무실 연락처 입력"
                  fullWidth
                  validateOptions={{
                    maxLength: 11,
                  }}
                  {...register('phoneNo', {
                    required: '',
                    maxLength: {
                      value: 11,
                      message: '',
                    },
                    minLength: {
                      value: 1,
                      message: '',
                    },
                    onChange: (e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    },
                  })}
                />
                <TextField
                  label="사무실 이메일"
                  placeholder="사무실 이메일 입력"
                  fullWidth
                  {...register('email')}
                />
                <TextField
                  label="사무실 팩스"
                  placeholder="사무실 팩스 입력"
                  fullWidth
                  {...register('faxNo')}
                />
                <TextField
                  label="담당자"
                  {...register('managerName')}
                  fullWidth
                />
                <TextField
                  label="담당자 연락처"
                  validateOptions={{
                    maxLength: 11,
                  }}
                  {...register('managerPhoneNo', {
                    required: '',
                    maxLength: {
                      value: 11,
                      message: '',
                    },
                    minLength: {
                      value: 1,
                      message: '',
                    },
                    onChange: (e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    },
                  })}
                  fullWidth
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button size="md" variant="solid" color="primary" type="submit">
                수정
              </Button>
              <Button
                size="md"
                variant="outlined"
                color="neutral"
                onClick={() => {
                  handleClose(false, 'closeClick');
                }}
              >
                취소
              </Button>
            </DialogActions>
          </ModalDialog>
        </Box>
      </Modal>
    </>
  );
};

export default ModifyUnionInfoModal;
