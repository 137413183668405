import { formatDate } from '@wooriga/common-utils';
import { GridColDef, Link } from '@wooriga/design-system';

import { ElectronicConsentRecipient } from 'apis/types/consent';
import { UnionRegisterInfo } from 'apis/types/union';
import ElectronicConsentSubmitStatusChip from 'components/Chips/ElectronicConsentSubmitStatusChip';
import { GridColumnsHandlers } from 'types/grid';

export const ELECTRONIC_CONSENT_TARGET_COLUMNS = ({
  onClickName,
  getCommonCode,
}: GridColumnsHandlers<{
  onClickName: (unionRegisterSeq: number) => void;
}>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      valueGetter: (_, row) => row?.unionRegisterNo || '',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'shareType',
      headerName: '소유구분',
      width: 100,
      minWidth: 100,
      valueGetter: (_, row) =>
        getCommonCode('UNION_REGISTER_SHARE_TYPE', row?.shareType)?.name || '',
    },
    {
      field: 'isRepresentative',
      headerName: '대표자',
      headerAlign: 'center',
      type: 'boolean',
    },
    {
      field: 'name',
      headerName: '이름',
      width: 160,
      minWidth: 160,
      valueGetter: (_, row) =>
        row?.name
          ? [row?.name?.name, row?.name?.nameClass].join('')
          : '이름없음',
      renderCell: (params) => {
        const { value, row } = params;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onClickName(row?.unionRegisterSeq)}
          >
            {value}
          </Link>
        );
      },
    },
    {
      field: 'ownerType',
      headerName: '개인/법인',
      valueGetter: (_, row) =>
        getCommonCode('UNION_REGISTER_USER_DIVIDE', row?.ownerType)?.name || '',
    },
    {
      field: 'areaDong',
      headerName: '법정동',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, dong }) => ({
          key: `area-dong-${localAddressSeq}`,
          value: dong,
        })),
    },
    {
      field: 'jibun',
      headerName: '지번',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, jibun }) => ({
          key: `jibun-${localAddressSeq}`,
          value: jibun,
        })),
    },
    {
      field: 'building',
      headerName: '건물명',
      flex: 1,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, building }) => ({
          key: `building-${localAddressSeq}`,
          value: building,
        })),
    },
    {
      field: 'apartmentDong',
      headerName: '동',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, apartmentDong }) => ({
          key: `apartmentDong-${localAddressSeq}`,
          value: apartmentDong,
        })),
    },
    {
      field: 'apartmentHosu',
      headerName: '호수',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, apartmentHosu }) => ({
          key: `apartmentHosu-${localAddressSeq}`,
          value: apartmentHosu,
        })),
    },
  ] as GridColDef<UnionRegisterInfo>[];

export const ELECTRONIC_CONSENT_RECIPIENTS_COLUMNS = ({
  onClickName,
  getCommonCode,
}: GridColumnsHandlers<{
  onClickName: (recipient: ElectronicConsentRecipient) => void;
}>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      valueGetter: (_, row) => row?.registers.unionRegisterNo || '',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'shareType',
      headerName: '소유구분',
      width: 100,
      minWidth: 100,
      valueGetter: (_, row) =>
        getCommonCode('UNION_REGISTER_SHARE_TYPE', row?.registers.shareType)
          ?.name || '',
    },
    {
      field: 'isRepresentative',
      headerName: '대표자',
      headerAlign: 'center',
      type: 'boolean',
    },
    {
      field: 'name',
      headerName: '이름',
      width: 160,
      minWidth: 160,
      valueGetter: (_, row) =>
        row?.registers.name
          ? [row?.registers.name?.name, row?.registers.name?.nameClass].join('')
          : '이름없음',
      renderCell: (params) => {
        const { value, row } = params;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onClickName(row)}
          >
            {value}
          </Link>
        );
      },
    },
    {
      field: 'ownerType',
      headerName: '개인/법인',
      valueGetter: (_, row) =>
        getCommonCode('UNION_REGISTER_USER_DIVIDE', row?.registers.ownerType)
          ?.name || '',
    },
    {
      field: 'areaDong',
      headerName: '법정동',
      multiline: true,
      valueGetter: (_, row) =>
        row?.registers.localAddresses?.map(({ localAddressSeq, dong }) => ({
          key: `area-dong-${localAddressSeq}`,
          value: dong,
        })),
    },
    {
      field: 'jibun',
      headerName: '지번',
      multiline: true,
      valueGetter: (_, row) =>
        row?.registers.localAddresses?.map(({ localAddressSeq, jibun }) => ({
          key: `jibun-${localAddressSeq}`,
          value: jibun,
        })),
    },
    {
      field: 'building',
      headerName: '건물명',
      flex: 1,
      multiline: true,
      valueGetter: (_, row) =>
        row?.registers.localAddresses?.map(({ localAddressSeq, building }) => ({
          key: `building-${localAddressSeq}`,
          value: building,
        })),
    },
    {
      field: 'apartmentDong',
      headerName: '동',
      multiline: true,
      valueGetter: (_, row) =>
        row?.registers.localAddresses?.map(
          ({ localAddressSeq, apartmentDong }) => ({
            key: `apartmentDong-${localAddressSeq}`,
            value: apartmentDong && `${apartmentDong}동`,
          }),
        ),
    },
    {
      field: 'apartmentHosu',
      headerName: '호수',
      multiline: true,
      valueGetter: (_, row) =>
        row?.registers.localAddresses?.map(
          ({ localAddressSeq, apartmentHosu }) => ({
            key: `apartmentHosu-${localAddressSeq}`,
            value: apartmentHosu && `${apartmentHosu}호`,
          }),
        ),
    },
    {
      field: 'landOwnedArea',
      headerName: '토지면적',
      multiline: true,
      valueGetter: (_, row) =>
        row?.registers.localAddresses?.map(
          ({ localAddressSeq, landOwnership }) => ({
            key: `landOwnedArea-${localAddressSeq}`,
            value:
              landOwnership &&
              landOwnership.ownedArea &&
              `${landOwnership.ownedArea}㎡`,
          }),
        ),
    },
    {
      field: 'buildingUsage',
      headerName: '건축물용도',
      multiline: true,
      valueGetter: (_, row) =>
        row?.registers.localAddresses?.map(
          ({ localAddressSeq, buildingOwnership }) => ({
            key: `buildingUsage-${localAddressSeq}`,
            value:
              getCommonCode(
                'BUILDING_USAGE',
                buildingOwnership?.buildingUsage ?? '',
              )?.name || '',
          }),
        ),
    },
    {
      field: 'buildingOwnedArea',
      headerName: '건축물면적',
      multiline: true,
      valueGetter: (_, row) =>
        row?.registers.localAddresses?.map(
          ({ localAddressSeq, buildingOwnership }) => ({
            key: `buildingOwnedArea-${localAddressSeq}`,
            value:
              buildingOwnership &&
              buildingOwnership.ownedArea &&
              `${buildingOwnership.ownedArea}㎡`,
          }),
        ),
    },
    {
      field: 'status',
      headerName: '제출상태',
      renderCell: (params) => {
        return (
          <ElectronicConsentSubmitStatusChip
            size="md"
            code={params.value}
            label={
              getCommonCode(
                'ELECTRONIC_CONSENT_RECIPIENT_SUBMIT_STATUS',
                params.value,
              )?.name || ''
            }
          />
        );
      },
    },
    {
      field: 'submittedAt',
      headerName: '제출일시',
      valueGetter: (value) => (value && formatDate(value)) || '',
    },
  ] as GridColDef<ElectronicConsentRecipient>[];
