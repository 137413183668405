import { Chip, ChipProps } from '@wooriga/design-system';

import CommonCode, { CommonCodeProps } from 'components/CommonCode';

export interface ElectronicConsentSubmitStatusChipProps
  extends Pick<CommonCodeProps, 'code'> {
  size?: ChipProps['size'];
  label?: string;
}

const ElectronicConsentSubmitStatusChip = ({
  code,
  size,
  label,
}: ElectronicConsentSubmitStatusChipProps) => {
  const getVariant = (): {
    variant?: ChipProps['variant'];
    color?: ChipProps['color'];
  } => {
    switch (code) {
      case 'NOT_SUBMITTED':
        return {};
      case 'AUTO_SUBMITTED':
      case 'SUBMITTED':
        return {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          color: 'success',
        };
      case 'WITHDRAWAL':
        return {
          color: 'danger',
        };

      default:
        return {};
    }
  };

  return (
    <Chip {...getVariant()} size={size ?? 'md'}>
      {label ?? (
        <CommonCode
          groupCode="ELECTRONIC_CONSENT_RECIPIENT_SUBMIT_STATUS"
          code={code}
        />
      )}
    </Chip>
  );
};

export default ElectronicConsentSubmitStatusChip;
