import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Card,
  Chip,
  ChipProps,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  ModalDialog,
  ModalOverflow,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { createElement, useCallback, useMemo } from 'react';

import {
  useDeleteElectronicConsentRecipient,
  useCreateElectronicConsentWithdrawalCancel,
  useCreateElectronicConsentWithdrawal,
} from 'apis/electronic-consents/apis';
import { ElectronicConsentRecipient } from 'apis/types/consent';
import { useUnionRegisterQuery } from 'apis/union/register/apis';
import CommonCode from 'components/CommonCode';
import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import useFeedback from 'hooks/useFeedback';
import { formatBirth, formatTelephone } from 'utils/format';

interface ConsentRecipientInfoModalProps {
  params: {
    unionSeq: number;
    consentSeq: number;
    recipient: ElectronicConsentRecipient;
    consentStatus: string;
  };
  open: boolean;
  onClose: (open: false) => void;
}

const ConsentRecipientInfoModal = ({
  params,
  open,
  onClose,
}: ConsentRecipientInfoModalProps) => {
  const { unionSeq, consentSeq, recipient, consentStatus } = params;
  const { registers: recipientUnionRegister, recipientSeq, status } = recipient;

  const queryClient = useQueryClient();

  const { data, isPending } = useUnionRegisterQuery({
    unionSeq,
    unionRegisterSeq: recipientUnionRegister.unionRegisterSeq,
  });

  const { snackbar, confirmDialog, alertDialog } = useFeedback();

  const { mutate: deleteRecipient, isPending: isDeletePending } =
    useDeleteElectronicConsentRecipient();
  const { mutate: withdrawRecipient, isPending: isWithdrawPending } =
    useCreateElectronicConsentWithdrawal();
  const {
    mutate: withdrawCancelRecipient,
    isPending: isWithdrawCancelPending,
  } = useCreateElectronicConsentWithdrawalCancel();

  const handleDelete = useCallback(() => {
    confirmDialog('제외하시면 동의서 제출이 불가능합니다.', {
      message: '해당 대상자를 제외하시겠습니가?',
      onConfirm: () => {
        deleteRecipient(
          { consentSeq, recipientSeq },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: [`/v2/electronic-consents/${consentSeq}/recipients`],
              });
              onClose(false);
            },
            onError: (error) => {
              snackbar(error.response?.data.message ?? error.message, {
                color: 'danger',
              });
            },
          },
        );
      },
    });
  }, [
    consentSeq,
    recipientSeq,
    queryClient,
    snackbar,
    confirmDialog,
    onClose,
    deleteRecipient,
  ]);

  const handleWithdrawal = useCallback(() => {
    const isWithdrawal = status === 'WITHDRAWAL';
    const detail = isWithdrawal
      ? '철회 취소 시, 해당 대상자의 제출 현황은 ‘동의간주’로 변경됩니다.'
      : '철회 시, 해당 대상자의 제출 현황은 ‘철회’로 변경됩니다.\n’철회 취소’를 통해 다시 복구할 수 있습니다.';
    const title = isWithdrawal
      ? '철회를 취소하시겠습니까?'
      : '대상자의 동의서를 철회하시겠습니까?';

    confirmDialog(detail, {
      message: title,
      onConfirm: () => {
        if (isWithdrawal) {
          withdrawCancelRecipient(
            { consentSeq, recipientSeq },
            {
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: [
                    `/v2/electronic-consents/${consentSeq}/recipients`,
                  ],
                });

                alertDialog("제출 현황이 '동의간주'로 변경되었습니다.", {
                  message: '철회 취소가 완료되었습니다.',
                  onSubmit: () => {
                    onClose(false);
                  },
                });
              },
              onError: (error) => {
                snackbar(error.response?.data.message ?? error.message, {
                  color: 'danger',
                });
              },
            },
          );
          return;
        }

        withdrawRecipient(
          { consentSeq, recipientSeq },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: [`/v2/electronic-consents/${consentSeq}/recipients`],
              });

              alertDialog("제출 현황이 '철회'로 변경되었습니다.", {
                message: '철회가 완료되었습니다.',
                onSubmit: () => {
                  onClose(false);
                },
              });
            },
            onError: (error) => {
              snackbar(error.response?.data.message ?? error.message, {
                color: 'danger',
              });
            },
          },
        );
      },
    });
  }, [
    status,
    consentSeq,
    recipientSeq,
    queryClient,
    snackbar,
    alertDialog,
    confirmDialog,
    onClose,
    withdrawRecipient,
    withdrawCancelRecipient,
  ]);

  const unionRegister = data?.data;

  const localAddressDescriptions = useMemo(() => {
    const { localAddresses } = unionRegister || {};

    const addresses =
      localAddresses?.map(
        ({
          city,
          gu,
          dong,
          jibun,
          zoneRoad,
          apartmentDong,
          apartmentFloor,
          apartmentHosu,
        }) => {
          const subAddress = dong ? [dong, jibun] : [zoneRoad];
          return [
            city,
            gu,
            ...subAddress,
            apartmentDong,
            apartmentFloor,
            apartmentHosu,
          ].join(' ');
        },
      ) || [];

    return (
      <Stack gap={0.75}>
        <Typography level="body-sm" textColor="text.primary">
          소재지 정보
        </Typography>
        <Card variant="outlined">
          <Stack gap={2} sx={{ maxHeight: '94px', overflowY: 'auto' }}>
            {addresses.map((address, index) => (
              <Typography
                key={`local_address_${index}`}
                level="body-sm"
                textColor="text.secondary"
              >
                {address}
              </Typography>
            ))}
          </Stack>
        </Card>
      </Stack>
    );
  }, [unionRegister]);

  const unionRegisterDescriptions = useMemo(() => {
    const {
      ownerType,
      unionRegisterNo,
      name,
      birth,
      gender,
      companyNo,
      shareType,
      positionDescription,
      hasVotingRight,
      salesCount,
      memo,
    } = unionRegister || {};

    const infoJSON =
      ownerType === 'PERSON'
        ? generateDescriptionsJSON(
            [
              '이름',
              '구분',
              '연번',
              '소유구분',
              '직책',
              '생년월일',
              '성별',
              '의결권',
              '분양권',
              '비고',
            ],
            [
              name && `${name?.name} ${name?.nameClass ?? ''}`,
              ownerType && (
                <CommonCode
                  groupCode="UNION_REGISTER_USER_DIVIDE"
                  code={ownerType}
                />
              ),
              unionRegisterNo,
              shareType && (
                <CommonCode
                  groupCode="UNION_REGISTER_SHARE_TYPE"
                  code={shareType}
                />
              ),
              positionDescription,
              formatBirth(birth),
              gender === 'M' ? '남자' : '여자',
              hasVotingRight ? '있음' : '없음',
              salesCount,
              memo,
            ],
            [2, 1, 1, 1, 1, 1, 1, 1, 1, 2],
          )
        : ownerType === 'COMPANY'
          ? generateDescriptionsJSON(
              [
                '이름',
                '구분',
                '연번',
                '소유구분',
                '직책',
                '사업자번호',
                '의결권',
                '분양권',
                '비고',
              ],
              [
                name && `${name?.name} ${name?.nameClass ?? ''}`,
                ownerType && (
                  <CommonCode
                    groupCode="UNION_REGISTER_USER_DIVIDE"
                    code={ownerType}
                  />
                ),
                unionRegisterNo,
                shareType && (
                  <CommonCode
                    groupCode="UNION_REGISTER_SHARE_TYPE"
                    code={shareType}
                  />
                ),
                positionDescription,
                formatBirth(companyNo),
                hasVotingRight ? '있음' : '없음',
                salesCount,
                memo,
              ],
              [2, 1, 1, 1, 1, 1, 1, 1, 2],
            )
          : generateDescriptionsJSON(
              [
                '이름',
                '구분',
                '연번',
                '소유구분',
                '직책',
                '의결권',
                '분양권',
                '비고',
              ],
              [
                name && `${name?.name} ${name?.nameClass ?? ''}`,
                ownerType && (
                  <CommonCode
                    groupCode="UNION_REGISTER_USER_DIVIDE"
                    code={ownerType}
                  />
                ),
                unionRegisterNo,
                shareType && (
                  <CommonCode
                    groupCode="UNION_REGISTER_SHARE_TYPE"
                    code={shareType}
                  />
                ),
                positionDescription,
                hasVotingRight ? '있음' : '없음',
                salesCount,
                memo,
              ],
              [2, 1, 1, 1, 1, 1, 1, 2],
            );

    return (
      <Stack gap={0.75}>
        <Typography level="body-sm" textColor="text.primary">
          조합원 정보
        </Typography>
        <DescriptionsRenderer columns={2} json={infoJSON} />
      </Stack>
    );
  }, [unionRegister]);

  const phoneDescriptions = useMemo(() => {
    const { mainPhone, subPhones } = unionRegister || {};

    const subPhoneLabels = subPhones?.map(() => '기타 연락처') || [];
    const subPhoneNos =
      subPhones?.map((subPhone, index) =>
        createElement(
          Grid,
          {
            key: `sub_phone_${index}`,
            container: true,
            spacing: 2,
            direction: 'row',
            justifyContent: 'space-between',
          },
          [
            formatTelephone(subPhone.phoneNo),
            subPhone.memo,
            createElement(
              Chip,
              {
                variant: 'soft',
                color: subPhone?.isContactAllowed
                  ? ('blue' as ChipProps['color'])
                  : 'danger',
              },
              subPhone?.isContactAllowed ? '수신' : '거부',
            ),
          ].map((value, index, { length }) =>
            createElement(
              Grid,
              {
                key: `sub_phone${value}_${index}`,
                ...(index !== 0 && index !== length - 1 ? { xs: 6 } : null),
              },
              value,
            ),
          ),
        ),
      ) || [];

    const phoneJSON = generateDescriptionsJSON(
      ['조합원 연락처', ...subPhoneLabels],
      [
        createElement(
          Grid,
          {
            container: true,
            spacing: 2,
            direction: 'row',
            justifyContent: 'space-between',
          },
          [
            formatTelephone(mainPhone?.phoneNo),
            '',
            createElement(
              Chip,
              {
                variant: 'soft',
                color: mainPhone?.isContactAllowed
                  ? ('blue' as ChipProps['color'])
                  : 'danger',
              },
              mainPhone?.isContactAllowed ? '수신' : '거부',
            ),
          ].map((value, index, { length }) =>
            createElement(
              Grid,
              {
                key: `main_phone_${value}_${index}`,
                ...(index !== 0 && index !== length - 1 ? { xs: 6 } : null),
              },
              value,
            ),
          ),
        ),
        ...subPhoneNos,
      ],
    );

    return (
      <Stack gap={0.75}>
        <Typography level="body-sm" textColor="text.primary">
          연락처 정보
        </Typography>
        <DescriptionsRenderer columns={1} json={phoneJSON} />
      </Stack>
    );
  }, [unionRegister]);

  const realAddressDescriptions = useMemo(() => {
    const { realAddress, postAddress, etcAddress } = unionRegister || {};

    let mainRealAddress = '';
    let mainPostAddress = '';

    if (realAddress) {
      const { address, detailAddress } = realAddress;
      mainRealAddress = `${address} ${detailAddress ?? ''}`;
    }

    if (postAddress) {
      const { address, detailAddress } = postAddress;
      mainPostAddress = [address, detailAddress].join(' ');
    }

    const postJSON = generateDescriptionsJSON(
      ['실제 거주지', '우편물 수령지', '기타 주소지'],
      [mainRealAddress, mainPostAddress, etcAddress],
    );

    return (
      <Stack gap={0.75}>
        <Typography level="body-sm" textColor="text.primary">
          주소 정보
        </Typography>
        <DescriptionsRenderer columns={1} json={postJSON} />
      </Stack>
    );
  }, [unionRegister]);

  const agentDescriptions = useMemo(() => {
    const { agent } = unionRegister || {};

    const { relationType, relationDescription, gender, name, birth, phoneNo } =
      agent || {};

    const relation =
      relationDescription ??
      (relationType && (
        <CommonCode groupCode="AGENT_RELATION_TYPE" code={relationType} />
      ));

    const agentInfoJSON = generateDescriptionsJSON(
      ['관계', '성별', '이름', '생년월일', '연락처'],
      [
        relation,
        gender === 'M' ? '남자' : gender === 'F' ? '여자' : '',
        name && `${name?.name} ${name?.nameClass ?? ''}`,
        formatBirth(birth),
        formatTelephone(phoneNo),
      ],
    );

    return (
      <Stack gap={0.75}>
        <Typography level="body-sm" textColor="text.primary">
          대리인 정보
        </Typography>
        <DescriptionsRenderer columns={2} json={agentInfoJSON} />
      </Stack>
    );
  }, [unionRegister]);

  const isButtonDisabled = useMemo(
    () =>
      isDeletePending ||
      isWithdrawPending ||
      isWithdrawCancelPending ||
      consentStatus === 'COMPLETED',
    [
      isDeletePending,
      isWithdrawPending,
      isWithdrawCancelPending,
      consentStatus,
    ],
  );

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalOverflow>
        <ModalDialog sx={{ width: 600 }}>
          <DialogTitle>조합원 정보</DialogTitle>

          <DialogContent sx={{ py: 1.5 }}>
            <Stack minHeight={700} gap={2}>
              {isPending && <CircularProgress sx={{ m: 'auto' }} />}
              {!isPending && (
                <>
                  {localAddressDescriptions}
                  {unionRegisterDescriptions}
                  {phoneDescriptions}
                  {realAddressDescriptions}
                  {agentDescriptions}
                </>
              )}
            </Stack>
          </DialogContent>

          <DialogActions>
            <Stack direction="row" justifyContent="space-between" flex={1}>
              <Stack direction="row" gap={1}>
                <Button
                  variant="outlined"
                  color="danger"
                  loading={isDeletePending}
                  disabled={isButtonDisabled}
                  onClick={handleDelete}
                >
                  대상자 제외
                </Button>

                {unionRegister?.ownerType === 'PUBLIC' && (
                  <Button
                    variant="outlined"
                    color="neutral"
                    loading={isWithdrawPending}
                    disabled={isButtonDisabled}
                    onClick={handleWithdrawal}
                  >
                    {status === 'WITHDRAWAL' ? '철회 취소' : '철회'}
                  </Button>
                )}
              </Stack>

              <Button onClick={() => onClose(false)}>확인</Button>
            </Stack>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default ConsentRecipientInfoModal;
