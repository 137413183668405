import { StateCreator } from 'zustand';

const DEFAULT_MENU_PANEL_STATE = {
  electronicConsent: {
    isAreaPanelOpen: false,
    isDongPanelOpen: false,
  },
};

export interface MenuPanelStates {
  electronicConsent: {
    isAreaPanelOpen: boolean;
    isDongPanelOpen: boolean;
  };
}

export type MenuPanelName =
  | 'ELECTRONIC_CONSENT_AREA'
  | 'ELECTRONIC_CONSENT_DONG';
export type MenuPanelSlice = {
  activatePanel: (name: MenuPanelName) => void;
  deactivatePanel: (name: MenuPanelName) => void;
  resetPanels: () => void;
} & MenuPanelStates;

const getChangedTargetPanels = (
  name: MenuPanelName,
  value: boolean,
  states: MenuPanelStates,
): MenuPanelStates => {
  if (name === 'ELECTRONIC_CONSENT_AREA')
    return {
      electronicConsent: {
        ...states.electronicConsent,
        isAreaPanelOpen: value,
      },
    };

  if (name === 'ELECTRONIC_CONSENT_DONG')
    return {
      electronicConsent: {
        ...states.electronicConsent,
        isDongPanelOpen: value,
      },
    };

  return states;
};

export const createMenuPanelSlice: StateCreator<
  MenuPanelSlice,
  [],
  [],
  MenuPanelSlice
> = (set) => ({
  ...DEFAULT_MENU_PANEL_STATE,
  activatePanel: (name) =>
    set(({ electronicConsent }) => ({
      ...getChangedTargetPanels(name, true, { electronicConsent }),
    })),
  deactivatePanel: (name) =>
    set(({ electronicConsent }) => ({
      ...getChangedTargetPanels(name, false, { electronicConsent }),
    })),
  resetPanels: () => set(() => DEFAULT_MENU_PANEL_STATE),
});
