import {
  ChangeEvent,
  Children,
  cloneElement,
  DragEvent,
  isValidElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { MIME_TYPES } from 'design-system/components/inputs/upload/constants';
import { isValidExtensions } from 'design-system/components/inputs/upload/utils';

export type FileSelectSlot = 'root' | 'label';

export interface FileSelectProps {
  error?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  id?: string;
  name?: string;
  accept?: string;
  enableDragOver?: boolean;
  children: ReactNode;
  onSelectFiles?: (files: FileList | null) => void;
  onSelectInvalidFiles?: (files: FileList | null) => void;
}

export type FileSelectOwnerState = FileSelectProps;

const FileSelect = (props: FileSelectProps) => {
  const {
    id,
    name,
    accept,
    disabled,
    enableDragOver,
    onSelectFiles,
    onSelectInvalidFiles,
    children,
    ...other
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const [dragOver, setDragOver] = useState<boolean>(false);

  const validExtensions = useMemo(() => {
    if (!accept) return null;

    const extensions = accept
      .split(',')
      .map((mimeType) => MIME_TYPES[mimeType.trim()] || mimeType.trim());

    return extensions;
  }, [accept]);

  const handleDragEnter = useCallback((e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  }, []);

  const handleDragOver = useCallback((e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
      setDragOver(true);
    }
  }, []);

  const handleSelectFiles = useCallback(
    (files: FileList | null) => {
      if (!files) return;

      const invalidFiles = validExtensions
        ? Array.from(files).filter(
            (file) => !isValidExtensions(file, validExtensions),
          )
        : [];

      if (invalidFiles.length > 0) {
        const dataTransfer = new DataTransfer();
        invalidFiles.forEach((file) => dataTransfer.items.add(file));
        return onSelectInvalidFiles?.(dataTransfer.files);
      }

      onSelectFiles?.(files);
    },
    [onSelectFiles, onSelectInvalidFiles, validExtensions],
  );

  const handleDrop = useCallback(
    (e: DragEvent<HTMLLabelElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setDragOver(false);

      if (e.dataTransfer) {
        const files = e.dataTransfer.files;
        handleSelectFiles(files);
      }
    },
    [handleSelectFiles],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;

      handleSelectFiles(files);

      // input 요소의 값 초기화
      e.target.value = '';
    },
    [handleSelectFiles],
  );

  const handleClick = useCallback(() => {
    inputRef?.current?.click?.();
  }, []);

  return (
    <>
      {useMemo(
        () =>
          Children.map(children, (child) => {
            if (isValidElement(child)) {
              const insertProps = {
                id,
                name,
                disabled,
                accept,
                onClick: handleClick,
                ...other,

                ...(enableDragOver && {
                  dragOver,
                  onDragEnter: handleDragEnter,
                  onDragLeave: handleDragLeave,
                  onDragOver: handleDragOver,
                  onDrop: handleDrop,
                }),
              };

              return cloneElement(child, {
                ...insertProps,
              });
            }

            return child;
          }),
        [
          accept,
          children,
          enableDragOver,
          disabled,
          dragOver,
          handleClick,
          handleDragEnter,
          handleDragLeave,
          handleDragOver,
          handleDrop,
          id,
          name,
          other,
        ],
      )}

      <input
        ref={inputRef}
        id={id}
        name={name}
        accept={accept}
        type="file"
        aria-hidden
        onChange={handleChange}
        disabled={disabled}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default FileSelect;
