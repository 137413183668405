import * as _ from 'lodash-es';
import * as qs from 'qs';

export const convertObject2QueryString = (params: object) => {
  return qs.stringify(params, { arrayFormat: 'comma' });
};

export const convertURLSearchParams2Object = (
  urlSearchParams: URLSearchParams,
) => {
  return qs.parse(urlSearchParams.toString());
};

export const convertObject2KeyValuePairs = (params: object) => {
  return _.entries(params);
};

export const parseNumberToLocaleString = (
  value: number,
  locales?: Intl.LocalesArgument,
  options?: Intl.NumberFormatOptions,
) => {
  return value.toLocaleString(locales || navigator.languages, options);
};

export * from 'common-utils/utils/byte';
export * from 'common-utils/utils/common';
export * from 'common-utils/utils/company';
export * from 'common-utils/utils/cookie';
export * from 'common-utils/utils/format';
export * from 'common-utils/utils/mock';
export * from 'common-utils/utils/excel';
export * from 'common-utils/utils/number';
export * from 'common-utils/utils/url';
