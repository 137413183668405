import { formatDateTime } from '@wooriga/common-utils';
import { Button, GridColDef, Link } from '@wooriga/design-system';

import {
  ElectronicConsentCertificate,
  ElectronicConsentRecipient,
} from 'apis/types/consent';
import CertificateIssuanceStatusChip from 'components/Chips/CertificateIssuanceStatusChip';
import { GridColumnsHandlers } from 'types/grid';

export const ELECTRONIC_CONSENT_CERTIFICATE_COLUMNS = ({
  onClickName,
  onClickExtension,
  getCommonCode,
}: GridColumnsHandlers<{
  onClickName: (meetSeq: number) => void;
  onClickExtension: (meetSeq: number) => void;
}>) =>
  [
    {
      field: 'no',
      headerName: 'No',
      width: 80,
      minWidth: 80,
      valueGetter: (_, row, __, apiRef) =>
        apiRef.current.getAllRowIds().length -
          apiRef.current.getAllRowIds().indexOf(row?.consentSeq) || '',
    },
    {
      field: 'title',
      headerName: '전자동의서 제목',
      width: 200,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const consentSeq = params.row.consentSeq;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onClickName(consentSeq)}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'templateName',
      headerName: '전자동의서 유형',
      width: 200,
      minWidth: 200,
      flex: 1,
      valueGetter: (_, row) => row.template.name || '',
    },
    {
      field: 'status',
      headerName: '동의서 상태',
      valueGetter: (_, row) =>
        row.status &&
        getCommonCode('ELECTRONIC_CONSENT_PROGRESS_STATUS', row.status)?.name,
    },
    {
      field: 'documentStorageEndAt',
      headerName: '문서보관 종료일',
      width: 110,
      minWidth: 110,
      //   valueGetter: (_, row) =>
      //     row.documentStorageEndAt && formatDate(row.documentStorageEndAt),
    },
    {
      field: 'documentStorageStatus',
      headerName: '문서보관 상태',
      valueGetter: (value) =>
        value && getCommonCode('DOCUMENT_STORAGE_STATUS', value)?.name,
    },
    {
      field: 'documentStorageExtensionStatus',
      headerName: '문서보관 연장',
      align: 'center',
      // valueGetter: (value) =>
      //   getCommonCode('DOCUMENT_KEEP_EXTENSION_STATUS', value)?.name,
      renderCell: (params) => {
        // const threeMonthsAgo = subMonths(new Date(), 3);

        // if (params.value !== 'AVAILABLE') {
        //   return <DocumentKeepExtensionStatusChip code="" />;
        // }

        return (
          <Button
            variant="outlined"
            color="neutral"
            size="sm"
            disabled
            // disabled={isBefore(params.row.documentStatus, threeMonthsAgo)}
            onClick={() => onClickExtension(params.row.consentSeq)}
          >
            연장신청
          </Button>
        );
      },
    },
  ] as GridColDef<ElectronicConsentCertificate>[];

export const CERTIFICATION_DETAIL_COLUMNS = ({
  onNameClick,
  getCommonCode,
}: GridColumnsHandlers<{
  onNameClick: (unionRegisterSeq: number) => void;
}>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      valueGetter: (_, row) => row?.registers.unionRegisterNo || '',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'shareType',
      headerName: '소유구분',
      width: 100,
      minWidth: 100,
      valueGetter: (_, row) =>
        getCommonCode('UNION_REGISTER_SHARE_TYPE', row?.registers.shareType)
          ?.name || '',
    },
    {
      field: 'isRepresentative',
      headerName: '대표자',
      headerAlign: 'center',
      type: 'boolean',
      valueGetter: (_, row) => row?.registers.isRepresentative,
    },
    {
      field: 'name',
      headerName: '이름',
      width: 160,
      minWidth: 160,
      renderCell: (params) => {
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onNameClick(params.row?.registers.unionRegisterSeq)}
          >
            {params.row.registers.name.name}
            {params.row.registers.name.nameClass}
          </Link>
        );
      },
    },
    {
      field: 'ownerType',
      headerName: '개인/법인',
      valueGetter: (_, row) =>
        getCommonCode('UNION_REGISTER_USER_DIVIDE', row?.registers.ownerType)
          ?.name || '',
    },
    {
      field: 'status',
      headerName: '제출여부',
      valueGetter: (value) =>
        getCommonCode('ELECTRONIC_CONSENT_RECIPIENT_SUBMIT_STATUS', value)
          ?.name,
    },
    {
      field: 'submittedAt',
      headerName: '제출일시',
      width: 200,
      minWidth: 200,
      valueFormatter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'downloadedAt',
      headerName: '최근 다운로드 일시',
      width: 200,
      minWidth: 200,
      valueFormatter: (_, row) =>
        formatDateTime(row.certificate?.downloadedAt, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'issuedStatus',
      headerName: '발급상태',
      valueGetter: (_, row) =>
        getCommonCode(
          'CERTIFICATE_ISSUED_STATUS',
          row.certificate?.issuedStatus ?? 'UNAVAILABLE',
        )?.name,
      renderCell: (params) => {
        const { value } = params;

        return (
          value && (
            <CertificateIssuanceStatusChip
              code={params.row.certificate?.issuedStatus ?? 'UNAVAILABLE'}
            />
          )
        );
      },
    },
  ] as GridColDef<ElectronicConsentRecipient>[];
