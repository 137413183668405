import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  DateTimePicker,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Option,
  Radio,
  RadioGroup,
  Select,
  Sheet,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  Switch,
  Textarea,
  TextField,
  Typography,
} from '@wooriga/design-system';
import dayjs from 'dayjs';
import { ChangeEvent, useMemo, useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
  useReadElectronicConsentTemplate,
  useReadElectronicConsentTemplates,
} from 'apis/electronic-consents/template/apis';
import useFeedback from 'hooks/useFeedback';
import selectionPage from 'pages/main/consent-management/open/selection';

const schema = yup.object({
  templateSeq: yup.number().required().min(1, '동의서 유형을 선택해 주세요.'),
  title: yup.string().required('제목을 입력해 주세요.'),
  content: yup.string().optional(),
  startedAt: yup.string().required('제출 시작일시를 설정해 주세요.'),
  endedAt: yup.string().when('isEndDateUnknown', {
    is: (value: boolean) => value === false,
    then: (schema) => schema.required('제출 종료일시를 설정해 주세요.'),
  }),
  isActiveCondition: yup.boolean().required(),
  isSignatureRequired: yup.boolean().required(),
  isEndDateUnknown: yup.boolean().required(),
  consentCondition: yup
    .number()
    .required('동의율을 입력해 주세요.')
    .min(1, '최소 1%부터 입력 가능합니다.')
    .max(100, '최대 100%까지 입력 가능합니다.'),
});

type FormValues = yup.InferType<typeof schema>;

const OpenPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();

  const unionSeq = Number(params.unionSeq);

  const { confirmDialog } = useFeedback();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      templateSeq: state?.templateSeq ?? 0,
      title: state?.title ?? '',
      content: state?.content ?? '',
      startedAt: state?.startedAt ?? '',
      endedAt: state?.endedAt ?? '',
      isSignatureRequired: state?.isSignatureRequired ?? true,
      isActiveCondition: state?.isActiveCondition ?? true,
      isEndDateUnknown: state?.isEndDateUnknown ?? false,
      consentCondition: state?.consentCondition ?? 100,
    },
  });

  const [isSignatureDisabled, setIsSignatureDisabled] =
    useState<boolean>(false);
  const [templateUrl, setTemplateUrl] = useState<string>('');

  const {
    data: templates,
    isError,
    error,
  } = useReadElectronicConsentTemplates({
    searchUnionSeq: unionSeq,
  });
  const { data: template } = useReadElectronicConsentTemplate(templateUrl);

  const handleChangeTemplateSelect = (
    field: ControllerRenderProps<FormValues>,
    templateSeq: string,
  ) => {
    const foundTemplate = templates?.data.find(
      (template) => template.templateSeq === Number(templateSeq),
    );

    if (!foundTemplate) return;

    field.onChange(Number(templateSeq));

    setTemplateUrl(foundTemplate.fileUrl);
    setIsSignatureDisabled(foundTemplate.signatureUsage !== 'OPTIONAL');
    setValue(
      'isSignatureRequired',
      foundTemplate.signatureUsage !== 'DISABLED',
    );
  };

  const handleBack = () => {
    if (!isDirty) {
      navigate('..', { replace: true });
      return;
    }

    confirmDialog('입력된 내용은 저장되지 않습니다.', {
      message: '전자동의서 등록을 취소하시겠습니까?',
      onConfirm: () => {
        navigate('..', { replace: true });
      },
    });
  };

  const validateSubmit = handleSubmit((data) => {
    const state = {
      ...data,

      endedAt: !data.isEndDateUnknown ? data.endedAt : null,
    };

    navigate('selection', { state });
  });

  const { startedAt, isEndDateUnknown, isActiveCondition } = watch();

  const minEndDate = useMemo(
    () => dayjs(startedAt).add(1, 'day').toString(),
    [startedAt],
  );
  const templatePreviews = useMemo(
    () =>
      template?.data.pages.map((page) => ({
        id: page.id,
        src: page.content.backgroundImage.src,
      })) ?? [],
    [template?.data],
  );

  if (isError) throw error;

  return (
    <Stack component="form" gap={3} onSubmit={validateSubmit}>
      <Box width={360} sx={{ alignSelf: 'flex-end' }}>
        <Stepper>
          <Step
            indicator={
              <StepIndicator variant="solid" color="primary">
                1
              </StepIndicator>
            }
          >
            전자 동의서 정보 입력
          </Step>
          <Step
            indicator={
              <StepIndicator variant="soft" color="primary">
                2
              </StepIndicator>
            }
          >
            대상자 선택
          </Step>
        </Stepper>
      </Box>

      <Grid container spacing={10}>
        <Grid xs={6}>
          <Stack gap={3}>
            <Stack gap={2}>
              <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
                전자 동의서 유형 선택
              </Typography>

              <Controller
                name="templateSeq"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error}>
                    <Select
                      placeholder="전자 동의서 유형 선택"
                      value={String(field.value)}
                      onChange={(_, value) =>
                        handleChangeTemplateSelect(field, value as string)
                      }
                    >
                      {(templates?.data ?? []).map(({ templateSeq, name }) => (
                        <Option
                          key={`template_option_${templateSeq}`}
                          value={`${templateSeq}`}
                        >
                          {name}
                        </Option>
                      ))}
                    </Select>
                    {error?.message && (
                      <FormHelperText>{error.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Stack>

            <Stack gap={2}>
              <Stack>
                <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
                  인증서 전자서명 여부
                </Typography>
                <Typography level="body-sm" textColor="text.tertiary">
                  전자서명은 증명서를 활용한 간편 인증 방식으로, 경우에 따라
                  자동으로 설정될 수 있습니다.
                </Typography>
              </Stack>

              <Controller
                name="isSignatureRequired"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    orientation="horizontal"
                    disabled={isSignatureDisabled}
                    value={String(field.value)}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      field.onChange(event.target.value === 'true');
                    }}
                  >
                    <Radio label="활성화" value="true" />
                    <Radio label="비활성화" value="false" />
                  </RadioGroup>
                )}
              />
            </Stack>

            <Stack gap={2}>
              <Stack>
                <Stack direction="row" gap={1}>
                  <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
                    공유자 동의 조건
                  </Typography>

                  <Controller
                    name="isActiveCondition"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        checked={field.value}
                        onChange={(event) =>
                          field.onChange(event.currentTarget.checked)
                        }
                      />
                    )}
                  />
                </Stack>
                <Typography level="body-sm" textColor="text.tertiary">
                  재개발 사업 추진을 위해 필요한 공유자의 동의 기준을
                  설정하세요.
                  <br />
                  설정된 조건은 동의권자 선정 및 동의 관리에 반영됩니다.
                </Typography>
              </Stack>

              <Controller
                name="consentCondition"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    disabled={!isActiveCondition}
                    type="number"
                    onChange={(event) =>
                      field.onChange(event.currentTarget.valueAsNumber)
                    }
                    validateOptions={{
                      regex: /^\d+(\.\d{1,3})?$/,
                    }}
                    slotProps={{ input: { step: 0.1, min: 1, max: 100 } }}
                    endDecorator={<Typography>%</Typography>}
                    sx={{ width: 140 }}
                    error={!!error}
                    helperText={error?.message ?? ''}
                  />
                )}
              />
            </Stack>

            <Stack gap={2}>
              <Stack>
                <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
                  전자 동의서 제출 및 발송 정보
                </Typography>
                <Typography level="body-sm" textColor="text.tertiary">
                  전자 동의서가 제출 가능한 시점이자 발송되는 시점이며, 설정한
                  시작일시에 동의서가 자동으로 발송됩니다.
                </Typography>
              </Stack>

              <Stack direction="row" gap={2} flexWrap="wrap">
                <Controller
                  name="startedAt"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl sx={{ flex: 1 }} error={!!error}>
                      <FormLabel>제출 시작일시*</FormLabel>
                      <DateTimePicker {...field} disablePast />
                      {error?.message && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />

                <Controller
                  name="endedAt"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl sx={{ flex: 1 }} error={!!error}>
                      <FormLabel>제출 종료일시</FormLabel>
                      <DateTimePicker
                        {...field}
                        disabled={isEndDateUnknown}
                        error={!!error}
                        minDateTime={minEndDate}
                      />
                      {error?.message && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Stack>

              <Stack direction="row" alignSelf="flex-end" gap={1}>
                <Typography>종료일시 미정</Typography>
                <Controller
                  name="isEndDateUnknown"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      onChange={(event) =>
                        field.onChange(event.currentTarget.checked)
                      }
                    />
                  )}
                />
              </Stack>
            </Stack>

            <Stack gap={2}>
              <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
                전자 동의서 내용
              </Typography>

              <Stack direction="column" gap={1}>
                <Controller
                  name="title"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="전자 동의서 제목"
                      placeholder="제목을 입력하세요."
                      error={!!error}
                      helperText={error?.message ?? ''}
                      validateOptions={{ maxLength: 50 }}
                    />
                  )}
                />

                <Controller
                  name="content"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel>전자 동의서 내용</FormLabel>
                      <Textarea
                        {...field}
                        variant="outlined"
                        color="neutral"
                        size="md"
                        placeholder="내용을 입력하세요."
                        sx={{ height: 120 }}
                        validateOptions={{ maxLength: 300 }}
                      />
                    </FormControl>
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
        </Grid>

        <Grid xs={6}>
          <Stack height="100%" gap={2}>
            <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
              전자 동의서 미리보기
            </Typography>

            <Sheet
              variant="soft"
              sx={{
                maxHeight: 810,
                flex: 1,
                borderRadius: 'md',
                overflow: 'auto',
              }}
            >
              {templatePreviews.map(({ id, src }, index) => (
                <img
                  key={`preview_${id}`}
                  src={src}
                  alt={`${index + 1}번째 동의서 미리보기`}
                  width="100%"
                />
              ))}
            </Sheet>
          </Stack>
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="space-between">
        <Button variant="outlined" color="neutral" onClick={handleBack}>
          취소
        </Button>
        <Button type="submit">다음</Button>
      </Stack>
    </Stack>
  );
};

const open = {
  path: 'open',
  children: [
    {
      path: '',
      index: true,
      element: <OpenPage />,
      handle: {
        getTitle: () => '동의서 등록',
      },
    },
    selectionPage,
  ],
  handle: {
    getTitle: () => '동의서 등록',
  },
};

export default open;
