import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import {
  ElectronicConsentCertificate,
  ElectronicConsentCertificateSummary,
} from 'apis/types/consent';
import { ApiError, ApiResponseData } from 'types/api';

/**
 * 전자 동의서 증명서 관련 APIs
 *
 * HTTP Method: GET
 */

// 전자동의서 증명서 목록 조회
type UseReadElectronicConsentCertificatesResult = UseQueryResult<
  ApiResponseData<ElectronicConsentCertificatesResponse>,
  ApiError
>;

export type ElectronicConsentCertificatesResponse =
  ElectronicConsentCertificate[];
export interface ElectronicConsentCertificatesParams {
  searchUnionSeq?: number;
  searchTitle?: string;
  searchStatus?: string;
}

export const useReadElectronicConsentCertificates = (
  params: ElectronicConsentCertificatesParams,
): UseReadElectronicConsentCertificatesResult => {
  return useQuery({
    queryKey: [`/v2/electronic-consents/certificates`, params],
  });
};

// 전자동의서 증명서 발급 상태 요약 조회
type UseReadElectronicConsentCertificateSummaryResult = UseQueryResult<
  ApiResponseData<ElectronicConsentCertificateSummaryResponse>,
  ApiError
>;

export type ElectronicConsentCertificateSummaryResponse =
  ElectronicConsentCertificateSummary['electronicCertificate'];
export interface ElectronicConsentCertificateSummaryParams {
  consentSeq: number;
}

export const useReadElectronicConsentCertificateSummary = ({
  consentSeq,
}: ElectronicConsentCertificateSummaryParams): UseReadElectronicConsentCertificateSummaryResult => {
  return useQuery({
    queryKey: [`/v2/electronic-consents/${consentSeq}/certificates/summary`],
  });
};

/**
 * 전자 동의서 증명서 관련 APIs
 *
 * HTTP Method: POST
 */

// 전자동의서 증명서 발급 신청
type UseCreateElectronicConsentCertificateResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentCertificateParams & ElectronicConsentCertificateBody
>;

export interface ElectronicConsentCertificateParams {
  consentSeq: number;
}

export interface ElectronicConsentCertificateBody {
  recipientSeqs: number[];
}

export const useCreateElectronicConsentCertificate =
  (): UseCreateElectronicConsentCertificateResult => {
    return useMutation({
      mutationFn: async ({ consentSeq, ...body }) => {
        const { data } = await axiosInstance.post(
          `/v2/electronic-consents/${consentSeq}/certificates`,
          body,
        );

        return data;
      },
    });
  };

type UseCreateElectronicConsentCertificateDownloadStatusResult =
  UseMutationResult<
    ApiResponseData,
    ApiError,
    ElectronicConsentCertificateDownloadStatusParams &
      ElectronicConsentCertificateDownloadStatusBody
  >;

export interface ElectronicConsentCertificateDownloadStatusParams {
  consentSeq: number;
}

export interface ElectronicConsentCertificateDownloadStatusBody {
  recipientSeqs: number[];
}

export const useCreateElectronicConsentCertificateDownloadStatus =
  (): UseCreateElectronicConsentCertificateDownloadStatusResult => {
    return useMutation({
      mutationFn: async ({ consentSeq, ...body }) => {
        const { data } = await axiosInstance.post(
          `/v2/electronic-consents/${consentSeq}/certificates/download-status`,
          body,
        );

        return data;
      },
    });
  };
