export const ELECTRONIC_CONSENT_CERTIFICATES_JSON = [
  {
    electronicConsent: {
      no: 1,
      consentSeq: 123,
      name: '123',
    },
    documentStorageEndAt: '2024-01-01T12:34:56',
    documentStorageStatus: 'STORING',
    documentStorageExtensionStatus: 'NONE',
  },
];

export const ELECTRONIC_CONSENT_CERTIFICATE_SUMMARY_JSON = {
  submitCertificate: {
    ableCount: 1,
    requestCount: 1,
    completeCount: 1,
    downloadCount: 1,
  },
  electronicCertificate: {
    ableCount: 1,
    requestCount: 1,
    completeCount: 1,
    downloadCount: 1,
  },
};
