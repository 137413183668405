import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import * as _ from 'lodash-es';
import { useCallback, useState } from 'react';

export interface TransferRowsProps<T> {
  path: string;
  defaultRows: T[];
}

const useTransferRows = <T>({ path, defaultRows }: TransferRowsProps<T>) => {
  const [oppositeRows, setOppositeRows] = useState<T[]>([]);

  const [defaultRowSelectionModel, setDefaultRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [oppositeRowSelectionModel, setOppositeRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const isRowSelectable = useCallback(
    ({ row }: { row: T }) =>
      !oppositeRows.some(
        (opposite) => _.get(opposite, path) === _.get(row, path),
      ),
    [path, oppositeRows],
  );

  const handleChangeDefaultSelection = useCallback(
    (rows: GridRowSelectionModel) => {
      setDefaultRowSelectionModel(rows);
    },
    [],
  );

  const handleChangeOppositeSelection = useCallback(
    (rows: GridRowSelectionModel) => {
      setOppositeRowSelectionModel(rows);
    },
    [],
  );

  const handleClickDefaultRowMovement = useCallback(() => {
    setOppositeRowSelectionModel([]);

    setOppositeRows(
      _.differenceWith(
        oppositeRows,
        oppositeRowSelectionModel,
        (row, id) => _.get(row, path) === id,
      ),
    );
  }, [path, oppositeRows, oppositeRowSelectionModel]);

  const handleClickOppositeRowMovement = useCallback(() => {
    setDefaultRowSelectionModel([]);

    const selectedRows = _.intersectionWith(
      defaultRows,
      defaultRowSelectionModel,
      (row, id) => _.get(row, path) === id,
    );

    setOppositeRows(_.concat(oppositeRows, selectedRows));
  }, [path, defaultRows, oppositeRows, defaultRowSelectionModel]);

  const handleReset = useCallback(() => {
    setOppositeRows([]);
    setDefaultRowSelectionModel([]);
    setOppositeRowSelectionModel([]);
  }, []);

  return {
    oppositeRows,
    defaultRowSelectionModel,
    oppositeRowSelectionModel,
    isRowSelectable,
    onDefaultRowSelectionModelChange: handleChangeDefaultSelection,
    onOppositeRowSelectionModelChange: handleChangeOppositeSelection,
    onClickDefaultRowMovement: handleClickDefaultRowMovement,
    onClickOppositeRowMovement: handleClickOppositeRowMovement,
    reset: handleReset,
  };
};

export default useTransferRows;
