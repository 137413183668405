export const ELECTRONIC_CONSENTS_JSON = [
  {
    consentSeq: 1,
    unionSeq: 1,
    templateSeq: 1,
    title: '제목',
    content: '내용',
    isSignatureRequired: true,
    isActiveCondition: true,
    consentCondition: 70,
    startedAt: '2024-12-17T15:10:00',
    endedAt: '2024-12-17T15:10:00',
    progressStatus: 'BEFORE',
    recipientCount: {
      total: 100,
      submitted: 50,
    },
  },
  {
    consentSeq: 2,
    unionSeq: 1,
    templateSeq: 1,
    title: '제목',
    content: '내용',
    isSignatureRequired: true,
    isActiveCondition: true,
    consentCondition: 70,
    startedAt: '2024-12-17T15:10:00',
    endedAt: '2024-12-17T15:10:00',
    progressStatus: 'IN_PROGRESS',
    recipientCount: {
      total: 100,
      submitted: 50,
    },
  },
  {
    consentSeq: 3,
    unionSeq: 1,
    templateSeq: 1,
    title: '제목',
    content: '내용',
    isSignatureRequired: true,
    isActiveCondition: true,
    consentCondition: 70,
    startedAt: '2024-12-17T15:10:00',
    endedAt: '2024-12-17T15:10:00',
    progressStatus: 'BEFORE',
    recipientCount: {
      total: 100,
      submitted: 50,
    },
  },
  {
    consentSeq: 4,
    unionSeq: 1,
    templateSeq: 1,
    title: '제목',
    content: '내용',
    isSignatureRequired: true,
    isActiveCondition: true,
    consentCondition: 70,
    startedAt: '2024-12-17T15:10:00',
    endedAt: '2024-12-17T15:10:00',
    progressStatus: 'COMPLETE',
    recipientCount: {
      total: 100,
      submitted: 50,
    },
  },
];

export const ELECTRONIC_CONSENT_RECIPIENTS_JSON = [
  {
    recipientSeq: 123,
    submitStatus: 'SUBMITTED',
    submittedAt: '2024-01-01T12:34:56',
    register: {
      unionRegisterSeq: 123,
      unionRegisterNo: '1-1',
      unionRegisterNoMain: 1,
      unionRegisterNoSub: 1,
      managementNo: 10,
      name: {
        name: '홍길동',
        nameClass: 'A',
      },
      birth: '19990123',
      companyNo: '1801110019681',
      phoneNo: '01012345678',
      shareType: 'SINGLE',
      ownerType: 'PERSON',
      isRepresentative: true,
      localAddresses: [
        {
          localAddressSeq: 123,
          registrationType: 'BUILDING',
          city: '서울특별시',
          gu: '서초구',
          dong: '양재동',
          jibun: '95-6',
          zoneRoad: '서울 서초구 양재천로 109-5 (소슬집)',
          building: '',
          apartmentDong: '201동',
          apartmentFloor: '3층',
          apartmentHosu: '201호',
          checkStatus: 'Y',
          landOwnerShip: {
            landCategory: 'BUILDING_LAND',
            portion: '1/2',
            totalArea: 25,
            ownedArea: 12.5,
            incorporatedArea: 12.5,
            usageCategory: 'COMMERCIAL',
            acquiredAt: '2024-01-01T12:34:56',
            hasSurfaceRight: true,
          },
          buildingOwnerShip: {
            buildingName: '우리가빌딩',
            buildingUsage: 'AMUSEMENT',
            buildingStructure: 'REINFORCED_CONCRETE',
            floor: '4',
            useApprovedAt: '2024-01-01T12:34:56',
            portion: '1/2',
            totalArea: 25,
            ownedArea: 12.5,
            acquiredAt: '12.5',
            isPermitted: true,
            unPermittedBuildingNo: '11170-등재-3722',
            associatedJibun: '111-32, 111-33, 111-34',
          },
        },
      ],
    },
  },
];

export const ELECTRONIC_CONSENT_ADD_RECIPIENTS_JSON = [
  {
    recipientSeq: 123,
    submitStatus: 'SUBMITTED', // SUBMITTED, WITHDRAWAL, NOT_SUBMITTED
    submittedAt: '2024-01-01T12:34:56',
    unionRegister: {
      unionRegisterSeq: 123,
      unionRegisterNo: '1-1',
      unionRegisterNoMain: 1,
      unionRegisterNoSub: 1,
      name: {
        name: '홍길동',
        nameClass: 'A',
      },
      shareType: 'SINGLE',
      ownerType: 'PERSON',
      isRepresentative: true,
      localAddresses: [
        {
          localAddressSeq: 123,
          registrationType: 'BUILDING',
          city: '서울특별시',
          gu: '서초구',
          dong: '양재동',
          jibun: '95-6',
          zoneRoad: '서울 서초구 양재천로 109-5 (소슬집)',
          building: '',
          apartmentDong: '201동',
          apartmentFloor: '3층',
          apartmentHosu: '201호',
          checkStatus: 'Y',
          landOwnerShip: {
            landCategory: 'BUILDING_LAND',
            portion: '1/2',
            totalArea: 25,
            ownedArea: 12.5,
          },
        },
      ],
    },
  },
  {
    recipientSeq: 124,
    submitStatus: 'WITHDRAWAL', // SUBMITTED, WITHDRAWAL, NOT_SUBMITTED
    submittedAt: '2024-01-01T12:34:56',
    unionRegister: {
      unionRegisterSeq: 123,
      unionRegisterNo: '1-1',
      unionRegisterNoMain: 1,
      unionRegisterNoSub: 1,
      name: {
        name: '다른이름',
        nameClass: 'A',
      },
      shareType: 'SINGLE',
      ownerType: 'PERSON',
      isRepresentative: true,
      localAddresses: [
        {
          localAddressSeq: 123,
          registrationType: 'BUILDING',
          city: '서울특별시',
          gu: '서초구',
          dong: '양재동',
          jibun: '95-6',
          zoneRoad: '서울 서초구 양재천로 109-5 (소슬집)',
          building: '',
          apartmentDong: '201동',
          apartmentFloor: '3층',
          apartmentHosu: '201호',
          checkStatus: 'Y',
          landOwnerShip: {
            landCategory: 'BUILDING_LAND',
            portion: '1/2',
            totalArea: 25,
            ownedArea: 12.5,
          },
        },
      ],
    },
  },
];

export const ELECTRONIC_CONSENT_STATISTICS_AREA_JSON = {
  totalArea: 1000.3,
  consentedArea: 300.5,
};

export const ELECTRONIC_CONSENT_STATISTICS_DONG_JSON = [
  {
    dong: 100,
    totalCount: 100,
    consentedCount: 10,
  },
  {
    dong: 101,
    totalCount: 100,
    consentedCount: 23,
  },
];
