import { useBoundStore } from 'stores';

const useMenuPanel = () => {
  const { electronicConsent, activatePanel, deactivatePanel, resetPanels } =
    useBoundStore();

  return { electronicConsent, activatePanel, deactivatePanel, resetPanels };
};

export default useMenuPanel;
