import { HttpHandler, HttpResponse, PathParams, http } from 'msw';

import { ConsentsRegisterSearchParams } from 'apis/agreements/apis';
import {
  ElectronicConsentCreateBody,
  ElectronicConsentRecipientDeleteParams,
  ElectronicConsentRecipientsUpdateBody,
  ElectronicConsentRecipientsUpdateParams,
  ElectronicConsentResponse,
  ElectronicConsentSendParams,
  ElectronicConsentsParams,
  ElectronicConsentsResponse,
  ElectronicConsentStatisticsAreaParams,
  ElectronicConsentStatisticsAreaResponse,
  ElectronicConsentStatisticsDongParams,
  ElectronicConsentStatisticsDongResponse,
  ElectronicConsentUpdateParams,
  ElectronicConsentWithdrawalCancelParams,
  ElectronicConsentWithdrawalParams,
} from 'apis/electronic-consents/apis';
import {
  ElectronicConsentCertificateBody,
  ElectronicConsentCertificateParams,
  ElectronicConsentCertificatesParams,
  ElectronicConsentCertificatesResponse,
  ElectronicConsentCertificateSummaryParams,
  ElectronicConsentCertificateSummaryResponse,
} from 'apis/electronic-consents/certificate/apis';
import {
  ELECTRONIC_CONSENT_CERTIFICATE_SUMMARY_JSON,
  ELECTRONIC_CONSENT_CERTIFICATES_JSON,
} from 'apis/electronic-consents/certificate/json';
import {
  ELECTRONIC_CONSENT_RECIPIENTS_JSON,
  ELECTRONIC_CONSENT_STATISTICS_AREA_JSON,
  ELECTRONIC_CONSENT_STATISTICS_DONG_JSON,
  ELECTRONIC_CONSENTS_JSON,
} from 'apis/electronic-consents/json';
import {
  ElectronicConsentTemplatesParams,
  ElectronicConsentTemplatesResponse,
} from 'apis/electronic-consents/template/apis';
import { ELECTRONIC_CONSENT_TEMPLATES_JSON } from 'apis/electronic-consents/template/json';
import { ApiErrorData, ApiResponseData } from 'types/api';

const handler: HttpHandler[] = [
  // 전자 동의서 양식 목록 조회
  http.get<
    PathParams<keyof ElectronicConsentTemplatesParams>,
    null,
    ApiResponseData<ElectronicConsentTemplatesResponse, true> & ApiErrorData
  >('/v2/electronic-consent-templates', async () => {
    return HttpResponse.json({
      status: 'success',
      data: ELECTRONIC_CONSENT_TEMPLATES_JSON,
      pagination: {
        totalDataCount: 10,
        totalElements: 10,
      },
    });
  }),

  // 전자 동의서 목록 조회
  http.get<
    PathParams<keyof ElectronicConsentsParams>,
    null,
    ApiResponseData<ElectronicConsentsResponse, true> & ApiErrorData
  >('/v2/electronic-consents', async () => {
    return HttpResponse.json({
      status: 'success',
      data: ELECTRONIC_CONSENTS_JSON,
      pagination: {
        totalDataCount: 10,
        totalElements: 10,
      },
    });
  }),

  // 전자 동의서 목록 상세 조회
  http.get<
    PathParams<keyof ConsentsRegisterSearchParams>,
    null,
    ApiResponseData<ElectronicConsentResponse> & ApiErrorData
  >('/v2/electronic-consents/:consentSeq', async () => {
    return HttpResponse.json({
      status: 'success',
      data: ELECTRONIC_CONSENTS_JSON[0],
    });
  }),

  // 전자 동의서 대상자들 조회
  http.get<
    PathParams<keyof ElectronicConsentsParams>,
    null,
    ApiResponseData<ElectronicConsentsResponse, true> & ApiErrorData
  >('/v2/electronic-consents/:consentSeq/recipients', async () => {
    return HttpResponse.json({
      status: 'success',
      data: ELECTRONIC_CONSENT_RECIPIENTS_JSON,
      pagination: {
        totalDataCount: 10,
        totalElements: 10,
      },
    });
  }),

  // 전자 동의서 면적 기준 동의율 조회
  http.get<
    PathParams<keyof ElectronicConsentStatisticsAreaParams>,
    null,
    ApiResponseData<ElectronicConsentStatisticsAreaResponse> & ApiErrorData
  >('/v2/electronic-consents/:consentSeq/statistics/area', () => {
    return HttpResponse.json({
      status: 'success',
      data: ELECTRONIC_CONSENT_STATISTICS_AREA_JSON,
    });
  }),

  // 전자 동의서 각 동별 기준 동의율 조회
  http.get<
    PathParams<keyof ElectronicConsentStatisticsDongParams>,
    null,
    ApiResponseData<ElectronicConsentStatisticsDongResponse> & ApiErrorData
  >('/v2/electronic-consents/:consentSeq/statistics/dong', () => {
    return HttpResponse.json({
      status: 'success',
      data: ELECTRONIC_CONSENT_STATISTICS_DONG_JSON,
    });
  }),

  // 전자 동의서 증명서 발급 목록 조회
  http.get<
    PathParams<keyof ElectronicConsentCertificatesParams>,
    null,
    ApiResponseData<ElectronicConsentCertificatesResponse> & ApiErrorData
  >('/v2/certificate-electronic-consents', () => {
    return HttpResponse.json({
      status: 'success',
      data: ELECTRONIC_CONSENT_CERTIFICATES_JSON,
    });
  }),

  // 전자 동의서 증명서 내역 요약 조회
  http.get<
    PathParams<keyof ElectronicConsentCertificateSummaryParams>,
    null,
    ApiResponseData<ElectronicConsentCertificateSummaryResponse> & ApiErrorData
  >('/v2/electronic-consents/:consentSeq/certificates/summary', () => {
    return HttpResponse.json({
      status: 'success',
      data: ELECTRONIC_CONSENT_CERTIFICATE_SUMMARY_JSON,
    });
  }),

  // 전자 동의서 등록
  http.post<
    PathParams<never>,
    ElectronicConsentCreateBody,
    ApiResponseData & ApiErrorData
  >(`/v2/electronic-consents`, () => {
    return HttpResponse.json({
      status: 'success',
    });
  }),

  // 전자 동의서 대상자 등록
  http.post<
    PathParams<keyof ElectronicConsentRecipientsUpdateParams>,
    ElectronicConsentRecipientsUpdateBody,
    ApiResponseData & ApiErrorData
  >(`/v2/electronic-consents/:consentSeq/recipients`, () => {
    return HttpResponse.json({
      status: 'success',
    });
  }),

  // 전자 동의서 철회
  http.post<
    PathParams<keyof ElectronicConsentWithdrawalParams>,
    null,
    ApiResponseData & ApiErrorData
  >(
    `/v2/electronic-consents/:consentSeq/recipients/:recipientSeq/withdraw`,
    () => {
      return HttpResponse.json({
        status: 'success',
      });
    },
  ),

  // 전자 동의서 철회
  http.post<
    PathParams<keyof ElectronicConsentWithdrawalCancelParams>,
    null,
    ApiResponseData & ApiErrorData
  >(
    `/v2/electronic-consents/:consentSeq/recipients/:recipientSeq/cancel`,
    () => {
      return HttpResponse.json({
        status: 'success',
      });
    },
  ),

  // 전자 동의서 재전송
  http.post<
    PathParams<keyof ElectronicConsentSendParams>,
    null,
    ApiResponseData & ApiErrorData
  >(`/v2/electronic-consents/:consentSeq/send`, () => {
    return HttpResponse.json({
      status: 'success',
    });
  }),

  // 전자 동의서 문서 보관 연장 신청
  http.post<
    PathParams<keyof ElectronicConsentCertificateParams>,
    ElectronicConsentCertificateBody,
    ApiResponseData & ApiErrorData
  >(`/v2/electronic-consents/:consentSeq/certificates`, () => {
    return HttpResponse.json({
      status: 'success',
    });
  }),

  // 전자 동의서 정보 변경
  http.put<
    PathParams<keyof ElectronicConsentUpdateParams>,
    Partial<ElectronicConsentCreateBody>,
    ApiResponseData & ApiErrorData
  >(`/v2/electronic-consents/:consentSeq`, () => {
    return HttpResponse.json({
      status: 'success',
    });
  }),

  // 전자 동의서 대상자 제외
  http.delete<
    PathParams<keyof ElectronicConsentRecipientDeleteParams>,
    null,
    ApiResponseData & ApiErrorData
  >(`/v2/electronic-consents/:consentSeq/recipients/:recipientSeq`, () => {
    return HttpResponse.json({
      status: 'success',
    });
  }),
];

export default handler;
