import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { commaizeNumber, formatDateTime } from '@wooriga/common-utils';
import {
  Button,
  DataGrid,
  Descriptions,
  DescriptionsItem,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  Stack,
  TextField,
  Typography,
  Link,
} from '@wooriga/design-system';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useCallback, useMemo, useState } from 'react';

import {
  useReadElectronicConsent,
  useReadElectronicConsentRecipients,
} from 'apis/electronic-consents/apis';
import {
  useCreateElectronicConsentCertificate,
  useCreateElectronicConsentCertificateDownloadStatus,
  useReadElectronicConsentCertificateSummary,
} from 'apis/electronic-consents/certificate/apis';
import { CERTIFICATION_DETAIL_COLUMNS } from 'apis/electronic-consents/certificate/fixture';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import RequestModal from 'components/pages/consent-management/certifications/modals/RequestModal';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import useQueryControls from 'hooks/useQueryControls';
import IndexTemplate from 'templates/IndexTemplate';

const defaultSearchParams = {
  searchRecipientName: '',
  searchStatus: '',
};

type ActionType = 'download' | 'request';
export interface CertificationTabProps {
  unionSeq: number;
  consentSeq: number;
}

const CertificationTab = ({ unionSeq, consentSeq }: CertificationTabProps) => {
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [certificateIssuanceStatus, setCertificateIssuanceStatus] =
    useState('');

  const [isRequestModalOpen, setIsRequestModalOpen] = useState<boolean>(false);
  const [openUnionRegisterDetailModal, setOpenUnionRegisterDetailModal] =
    useState(false);
  const [selectUnionRegisterSeq, setSelectUnionRegisterSeq] = useState<
    number | undefined
  >(undefined);

  const { pageContext } = useLayoutContext();

  const { snackbar, alertDialog, confirmDialog } = useFeedback();
  const { getGroupCode } = useCommonCodes();
  const { searchState, setSearchState, initState } = useQueryControls({
    defaultSearchParams,
    initSearchParams: defaultSearchParams,
  });

  const { data: consentInfo } = useReadElectronicConsent({ consentSeq });
  const { data: summary, refetch: refetchSummary } =
    useReadElectronicConsentCertificateSummary({
      consentSeq,
    });
  const { data: recipients, refetch: refetchRecipients } =
    useReadElectronicConsentRecipients({
      consentSeq,
      ...searchState,
    });
  const { mutate: requestCertification } =
    useCreateElectronicConsentCertificate();
  const { mutate: requestDownloadStatus } =
    useCreateElectronicConsentCertificateDownloadStatus();

  const availableIssuanceRequestRecipients = useMemo(
    () =>
      recipients?.data.filter(
        ({ recipientSeq, ...restProps }) =>
          rowSelectionModel.includes(recipientSeq) &&
          restProps.certificate?.issuedStatus === 'AVAILABLE',
      ) ?? [],
    [recipients, rowSelectionModel],
  );
  const availableCertificateDownloadRecipients = useMemo(
    () =>
      recipients?.data.filter(
        ({ recipientSeq, certificate }) =>
          rowSelectionModel.includes(recipientSeq) &&
          certificate?.issuedStatus === 'DOWNLOAD',
      ) ?? [],
    [recipients, rowSelectionModel],
  );

  const alertDataGridSelectionErrorMessage = useCallback(
    (actionType: ActionType) => {
      const title = actionType === 'download' ? '다운로드' : '발급 신청';
      const survey = actionType === 'download' ? '가' : '이';

      alertDialog(
        `요청하신 증명서를 ${title}할 수 없습니다.\n${title}${survey} 가능한 대상자를 선택해 주세요.`,
      );
    },
    [alertDialog],
  );

  const handleOnSelect = (value: string) => {
    setCertificateIssuanceStatus(value);
  };

  const handleClickName = useCallback((unionRegisterSeq: number) => {
    setSelectUnionRegisterSeq(unionRegisterSeq);
    setOpenUnionRegisterDetailModal(true);
  }, []);

  const handleRowSelectedModelChange = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      setRowSelectionModel(rowSelectionModel);
    },
    [],
  );

  const handleOpenRequestModal = useCallback(() => {
    if (
      !availableIssuanceRequestRecipients ||
      availableIssuanceRequestRecipients.length === 0
    ) {
      alertDataGridSelectionErrorMessage('request');
      return;
    }

    setIsRequestModalOpen(true);
  }, [availableIssuanceRequestRecipients, alertDataGridSelectionErrorMessage]);

  const handleDownload = useCallback(() => {
    if (
      !availableCertificateDownloadRecipients ||
      availableCertificateDownloadRecipients.length === 0
    ) {
      alertDataGridSelectionErrorMessage('download');
      return;
    }

    confirmDialog(
      `총 ${commaizeNumber(rowSelectionModel.length)}건 중 ${commaizeNumber(availableCertificateDownloadRecipients.length)}건에 대해 다운로드가 가능합니다.`,
      {
        message: '다운로드를 진행하시겠습니까?',
        onConfirm: () => {
          requestDownloadStatus(
            {
              consentSeq,
              recipientSeqs: availableCertificateDownloadRecipients.map(
                ({ recipientSeq }) => recipientSeq,
              ),
            },
            {
              onSuccess: () => {
                refetchRecipients();
              },
            },
          );

          availableCertificateDownloadRecipients?.forEach((recipients) => {
            if (!recipients.certificate?.certificateFilePath) return;

            const currentDate = formatDateTime(
              new Date(),
              'yyyy-MM-dd_HH:mm:ss',
            );

            axios(recipients.certificate.certificateFilePath, {
              responseType: 'blob',
            }).then(({ data }) =>
              saveAs(data, `전자 문서 증명서_${currentDate}.pdf`),
            );
          });
        },
      },
    );
  }, [
    consentSeq,
    rowSelectionModel,
    availableCertificateDownloadRecipients,
    alertDataGridSelectionErrorMessage,
    requestDownloadStatus,
    confirmDialog,
    refetchRecipients,
  ]);

  const handleRequest = useCallback(() => {
    const filteredRowSelection = availableIssuanceRequestRecipients.map(
      ({ recipientSeq }) => recipientSeq,
    );

    if (!filteredRowSelection || filteredRowSelection.length === 0) {
      alertDataGridSelectionErrorMessage('request');
      return;
    }

    requestCertification(
      {
        consentSeq,
        recipientSeqs: filteredRowSelection as number[],
      },
      {
        onSuccess: () => {
          snackbar('발급 신청이 완료되었습니다.', { color: 'success' });
          refetchSummary();
          refetchRecipients();
        },
        onError: (error) => {
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  }, [
    consentSeq,
    availableIssuanceRequestRecipients,
    alertDataGridSelectionErrorMessage,
    requestCertification,
    refetchSummary,
    refetchRecipients,
    snackbar,
  ]);

  const handleClick = useCallback(
    (actionType: ActionType) => () => {
      if (rowSelectionModel.length === 0) {
        alertDataGridSelectionErrorMessage(actionType);
        return;
      }

      actionType === 'download' ? handleDownload() : handleRequest();
    },
    [
      rowSelectionModel,
      alertDataGridSelectionErrorMessage,
      handleDownload,
      handleRequest,
    ],
  );

  const rows = useMemo(() => recipients?.data ?? [], [recipients]);
  const { columns } = useCreateGridColumns(CERTIFICATION_DETAIL_COLUMNS, {
    onNameClick: handleClickName,
  });

  const meetCertIssuableStatusCodeGroup = useMemo(
    () => getGroupCode('CERTIFICATE_ISSUED_STATUS'),
    [getGroupCode],
  );

  const totalElements = recipients?.pagination?.totalElements || 0;
  const totalDataCount = recipients?.pagination?.totalDataCount || 0;
  const availableCount = summary?.data.availableCount || 0;
  const pendingCount = summary?.data.pendingCount || 0;
  const unavailableCount = summary?.data.unavailableCount || 0;
  const downloadCount = summary?.data.downloadCount || 0;

  return (
    <Stack gap={2}>
      <Typography level="title-lg" marginBottom="15px">
        {consentInfo?.data.title ?? ''}
      </Typography>

      <Descriptions legacy={false} columns={4}>
        <DescriptionsItem label="신청가능">
          {commaizeNumber(availableCount)}건
        </DescriptionsItem>
        <DescriptionsItem label="신청중">
          {commaizeNumber(pendingCount)}건
        </DescriptionsItem>
        <DescriptionsItem label="신청불가">
          {commaizeNumber(unavailableCount)}건
        </DescriptionsItem>
        <DescriptionsItem label="다운로드">
          {commaizeNumber(downloadCount)}건
        </DescriptionsItem>
      </Descriptions>

      <IndexTemplate
        SearchComponent={
          <Search
            values={searchState}
            onSubmit={setSearchState}
            onReset={initState}
          >
            <Grid container gap={2}>
              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    placeholder="이름 검색"
                    label="이름"
                    name="searchRecipientName"
                    fullWidth
                    validateOptions={{
                      maxLength: 30,
                    }}
                  />
                </Search.Field>
              </Grid>

              <Grid xs={12} maxWidth={200}>
                <FormControl sx={{ width: '100%' }}>
                  <FormLabel>발급상태</FormLabel>
                  <Search.SelectField>
                    <Select
                      sx={{ width: '100%' }}
                      name="searchStatus"
                      value={certificateIssuanceStatus}
                      onChange={(_, value) =>
                        handleOnSelect(value as unknown as string)
                      }
                    >
                      <Option value="">전체</Option>

                      {meetCertIssuableStatusCodeGroup?.items.map((item) => (
                        <Option key={item.code} value={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Search.SelectField>
                </FormControl>
              </Grid>
            </Grid>
          </Search>
        }
        TopPanelComponent={
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(totalDataCount)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(totalElements)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              선택{' '}
              <Typography color="primary">
                {commaizeNumber(rowSelectionModel.length)}
              </Typography>
            </Typography>
          </Stack>
        }
        IndexComponent={
          <Stack height={442}>
            <DataGrid
              rows={rows}
              columns={columns}
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={handleRowSelectedModelChange}
              getRowId={(row) => row.recipientSeq}
              checkboxSelection
              disableRowSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: 'unionRegisterNo', sort: 'asc' }],
                },
              }}
            />
          </Stack>
        }
        BottomPanelComponent={
          <Stack direction="row" justifyContent="space-between">
            <Button
              variant="outlined"
              color="neutral"
              component={Link}
              href={`/unions/${unionSeq}/consent-management/certification`}
            >
              이전
            </Button>

            <Stack direction="row" gap={1}>
              <Button variant="outlined" onClick={handleClick('download')}>
                증명서 다운로드
              </Button>
              <Button onClick={handleOpenRequestModal}>발급 신청</Button>
            </Stack>
          </Stack>
        }
      />

      {selectUnionRegisterSeq && openUnionRegisterDetailModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq: selectUnionRegisterSeq }}
          open={openUnionRegisterDetailModal}
          onClose={setOpenUnionRegisterDetailModal}
        />
      )}

      <RequestModal
        requestor={pageContext?.memberInfo?.name || ''}
        certificationType="전자 문서 증명서"
        requestCount={rowSelectionModel.length}
        availableCount={availableIssuanceRequestRecipients.length}
        cost={0}
        open={isRequestModalOpen}
        onClose={setIsRequestModalOpen}
        onSubmit={handleClick('request')}
      />
    </Stack>
  );
};

export default CertificationTab;
