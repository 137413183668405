import { yupResolver } from '@hookform/resolvers/yup';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  Button,
  DataGrid,
  Grid,
  Link,
  Option,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
  useExternalSystemCheckMutation,
  useFileUploadMutation,
} from 'apis/common/apis';
import {
  CreatePostDocumentBody,
  CreatePostSendBody,
  useCreatePostBalanceMutation,
  useCreatePostDocumentMutation,
  useCreatePostSendMutation,
  useDeletePostDocumentMutation,
} from 'apis/post/apis';
import {
  POST_REGISTER_DEFAULT_VALUE,
  POST_STAPLE_OPTIONS,
  POST_TABS,
} from 'apis/post/constants';
import {
  POST_REGISTER_COLUMNS,
  PostRegisterColumnProps,
} from 'apis/post/fixtures';
import { FileInfo } from 'apis/types/common';
import { UnionRegisterInfo } from 'apis/types/union';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import PostBalanceModal from 'components/pages/posts/modal/PostBalanceModal';
import PostDocumentPreviewModal from 'components/pages/posts/modal/PostDocumentPreviewModal';
import PostDocumentSelectionModal from 'components/pages/posts/modal/PostDocumentSelectionModal';
import PostReceiverAdditionModal, {
  PostReceiverAdditionValues,
} from 'components/pages/posts/modal/PostReceiverAdditionModal';
import PostSenderForm from 'components/pages/posts/PostSenderForm';
import PostBalanceStep from 'components/pages/posts/stepper/PostBalanceStep';
import PostDocumentPreviewStep from 'components/pages/posts/stepper/PostDocumentPreviewStep';
import Search from 'components/Search';
import StepperDialog from 'components/StepperDialog';
import useCreateGridColumns from 'hooks/useCreateGridColumns_legacy';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import { useUnionRegisterGroupsQuery } from 'lim/address-group/apis';
import {
  UnionRegisterGroupRegistersParams,
  useUnionRegisterGroupRegistersQuery,
} from 'lim/address-group/detail/apis';
import postRecordPage from 'pages/main/union-management/posts/records';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

type ModalState<T> = {
  [key in keyof T]: boolean;
};

const FORM_DEFAULT_VALUES = {
  name: '',
  phoneNo: '',
  address: '',
  detailAddress: '',
  zipNo: '',
  sendQuantity: 0,
  fileSeq: 0,
  postType: 'COMMON',
  colorType: 'COLOR',
  flexType: 'CROSS',
  isStapler: POST_STAPLE_OPTIONS[0].value,
  recipients: [],
};
const DEFAULT_SEARCH_PARAMS = {
  name: '',
  unionRegisterNo: '',
};
const DEFAULT_GROUP_SEARCH_PARAMS = {
  unionRegisterGroupName: '',
  unionRegisterName: '',
};

const DEFAULT_MODAL_STATE = {
  unionInfo: false,
  stepper: false,
  balance: false,
  documentPreview: false,
  documentSelection: false,
  recipientAddition: false,
};

const schema = yup.object({
  name: yup.string().required('발송인 이름을 입력해 주세요'),
  phoneNo: yup.string().required('발송인 연락처를 입력해 주세요'),
  address: yup.string().required('발송인 주소를 검색해 주세요'),
  detailAddress: yup.string().optional().default(''),
  zipNo: yup.string().required(),
  sendQuantity: yup.number().required(),
  fileSeq: yup.number().required(),
  postType: yup.string().required('발송방식을 선택해 주세요'),
  colorType: yup.string().required('출력색상을 선택해 주세요'),
  flexType: yup.string().required('출력형태을 선택해 주세요'),
  isStapler: yup.boolean().required('스테이플러 여부를 선택해 주세요'),
  recipients: yup.array().required().min(1, ''),
});

export type PostRecipientRow = UnionRegisterInfo & {
  id: number | string;
  detailAddressInput?: string;
};

type PostModalState = ModalState<typeof DEFAULT_MODAL_STATE>;
type PostModalName = keyof PostModalState;

const PostPage = () => {
  const params = useParams();
  const unionSeq = Number(params.unionSeq);
  const { pageContext } = useLayoutContext();
  const { snackbar, alertDialog } = useFeedback();
  const { getGroupCode } = useCommonCodes();
  const { postTypeCodeGroup, colorTypeCodeGroup, flexTypeCodeGroup } = useMemo(
    () => ({
      postTypeCodeGroup: getGroupCode('POST_SEND_DIVIDE'),
      colorTypeCodeGroup: getGroupCode('POST_COLOR_DIVIDE'),
      flexTypeCodeGroup: getGroupCode('POST_FLEX_DIVIDE'),
    }),
    [getGroupCode],
  );

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [searchParams, setSearchParams] =
    useState<UnionRegisterGroupRegistersParams>(DEFAULT_SEARCH_PARAMS);

  const [rows, setRows] = useState<PostRecipientRow[]>([]);
  const [files, setFiles] = useState<(File | FileInfo)[]>([]);
  const [selectedUnionSeq, setSelectedUnionSeq] = useState(0);
  const [selectedGroupSeq, setSelectedGroupSeq] = useState<string>('');
  const [latestUploadFile, setLatestUploadFile] = useState<FileInfo | null>(
    null,
  );
  const [isNewFileUpdate, setIsNewFileUpdate] = useState(false);
  const [isPostPreviewChecked, setIsPostPreviewChecked] = useState(false);

  const [modals, setModals] = useState<PostModalState>(DEFAULT_MODAL_STATE);

  const { data: groups } = useUnionRegisterGroupsQuery(
    unionSeq,
    DEFAULT_GROUP_SEARCH_PARAMS,
  );
  const {
    data: sendersReturnData,
    isPending,
    isError,
    error,
  } = useUnionRegisterGroupRegistersQuery(
    unionSeq,
    Number(selectedGroupSeq),
    searchParams,
  );

  const { mutateAsync: uploadFiles, isPending: isFileUploading } =
    useFileUploadMutation();
  const { mutateAsync: checkPostSystem } = useExternalSystemCheckMutation();

  const { mutate: sendPost, isPending: isPostSending } =
    useCreatePostSendMutation();
  const { mutate: createPostDocument } = useCreatePostDocumentMutation();
  const { mutate: deletePostDocument } = useDeletePostDocumentMutation();
  const { mutate: calculatePostBalance } = useCreatePostBalanceMutation();

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<CreatePostSendBody>({
    resolver: yupResolver(schema),
    defaultValues: FORM_DEFAULT_VALUES,
  });

  const formValues = watch();

  const isFileSeqCached = useMemo(
    () => (formValues.fileSeq && !isNewFileUpdate) || isPostPreviewChecked,
    [formValues.fileSeq, isNewFileUpdate, isPostPreviewChecked],
  );

  const resetPageState = useCallback(() => {
    setFiles([]);
    setRowSelectionModel([]);
    setLatestUploadFile(null);
    reset(FORM_DEFAULT_VALUES);
  }, [reset]);

  const spreadRecipients = useCallback(
    (rows: UnionRegisterInfo[]) =>
      rows.map(
        ({ unionRegisterSeq, name, mainPhone, subPhones, postAddress }) => ({
          ...postAddress,
          unionRegisterSeq,
          name: name?.name,
          phoneNo:
            mainPhone?.phoneNo ??
            (Array.isArray(subPhones) && subPhones.length > 0
              ? subPhones[0].phoneNo
              : ''),
        }),
      ),
    [],
  );

  const openModal = useCallback((modalName: PostModalName) => {
    setModals((prevState) => ({ ...prevState, [modalName]: true }));
  }, []);
  const closeModal = useCallback((modalName: PostModalName) => {
    setModals((prevState) => ({ ...prevState, [modalName]: false }));
  }, []);

  const handleSearchParams = useCallback(
    (values: UnionRegisterGroupRegistersParams) => {
      setRowSelectionModel([]);
      setSearchParams(values);
    },
    [],
  );

  const handleSearchReset = useCallback(() => {
    setRowSelectionModel([]);
    setSearchParams(DEFAULT_SEARCH_PARAMS);
  }, []);

  const handleProcessRowUpdate = useCallback(
    (updateRow: PostRecipientRow) => {
      let newRow = updateRow;
      const isDirty = 'detailAddressInput' in updateRow;

      if (isDirty) {
        const detailAddress = newRow['detailAddressInput'] || '';
        delete newRow['detailAddressInput'];

        newRow = {
          ...newRow,
          postAddress: {
            ...newRow.postAddress,
            detailAddress,
          },
        };
      }

      const newRows: PostRecipientRow[] = rows.map((row) =>
        newRow.id === row.id ? newRow : row,
      );

      const selectedUnionRegisters = newRows.filter((row) =>
        rowSelectionModel.includes(row.id),
      );

      setValue('recipients', spreadRecipients(selectedUnionRegisters));
      setRows(newRows);

      return newRow;
    },
    [rowSelectionModel, rows, setValue, spreadRecipients],
  );

  const handleChangeRowSelection = useCallback(
    (model: GridRowSelectionModel) => {
      const selectedUnionRegisters = rows.filter((row) =>
        model.includes(row.id),
      );

      setRowSelectionModel(model);
      setValue('recipients', spreadRecipients(selectedUnionRegisters));
    },
    [rows, setValue, spreadRecipients],
  );

  const handleChangeRegisterAddress = useCallback(
    (result, id) => {
      const { userSelectedType, jibunAddress, roadAddress, zonecode } = result;

      const address = userSelectedType === 'R' ? roadAddress : jibunAddress;
      const newRows = rows.map((row, index) =>
        id === index + 1
          ? {
              ...row,
              postAddress: { ...row.postAddress, address, zipNo: zonecode },
            }
          : row,
      );
      const selectedUnionRegisters = newRows.filter(({ unionRegisterSeq }) =>
        rowSelectionModel.includes(unionRegisterSeq),
      );

      setValue('recipients', spreadRecipients(selectedUnionRegisters));
      setRows(newRows);
    },
    [rows, setValue, spreadRecipients, rowSelectionModel],
  ) as PostRegisterColumnProps['onClickAddress'];

  const handleChangeFiles = useCallback(
    <T extends File | FileInfo>(files: T[]) => {
      setIsNewFileUpdate(true);
      setIsPostPreviewChecked(false);
      setFiles(files);
    },
    [],
  );

  const handleUploadFiles = useCallback(async () => {
    const fileList = new DataTransfer();

    files.forEach((file) => {
      if (file instanceof File) {
        fileList.items.add(file);
      }
    });

    return await uploadFiles(
      { divisionCode: 'POST', files: fileList.files },
      {
        onSuccess: ({ data }) => {
          if (!data) {
            return;
          }

          setLatestUploadFile(data[0]);
          setValue('fileSeq', data[0].fileSeq);
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  }, [files, setValue, snackbar, uploadFiles]);

  const handleCheckPostSystem = useCallback(async () => {
    await checkPostSystem(
      { externalSystemType: 'POST_SEND' },
      {
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
          return false;
        },
      },
    );

    return true;
  }, [checkPostSystem, snackbar]);

  const handleOpenUnionInfoModal = useCallback(
    (seq: number | undefined) => {
      if (!seq) {
        alertDialog('조합원이 아니거나 조합원 정보가 없습니다.', {
          message: '조합원 정보',
        });
        return;
      }

      setSelectedUnionSeq(seq);
      openModal('unionInfo');
    },
    [alertDialog, openModal],
  );

  const handleOpenDocumentPreviewModal = () => {
    if (!isFileSeqCached) {
      handleUploadFiles();
    }

    openModal('documentPreview');
    setIsPostPreviewChecked(true);
    setIsNewFileUpdate(false);
  };

  const handleOpenBalanceModal = useCallback(async () => {
    const isSystemAvailable = await handleCheckPostSystem();

    if (!isSystemAvailable) {
      return;
    }

    calculatePostBalance(
      {
        unionSeq,
        ...formValues,
      },
      {
        onSuccess: () => {
          openModal('balance');
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  }, [
    calculatePostBalance,
    formValues,
    handleCheckPostSystem,
    openModal,
    snackbar,
    unionSeq,
  ]);

  const handleOpenPostStepper = handleSubmit(async () => {
    if (isFileSeqCached) {
      handleOpenBalanceModal();
      return;
    }

    await handleUploadFiles();

    openModal('stepper');
    setIsPostPreviewChecked(true);
    setIsNewFileUpdate(false);
  });

  const handleCreatePostDocument = useCallback(
    ({ fileSeq, formName }: CreatePostDocumentBody) => {
      const request = {
        unionSeq,
        fileSeq,
        formName,
      };

      createPostDocument(request, {
        onSuccess: () => {
          snackbar('서식 등록에 성공하였습니다.', {
            color: 'success',
          });
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      });
    },
    [createPostDocument, snackbar, unionSeq],
  );

  const handleDeletePostDocument = useCallback(
    (postFormSeqs: number[]) => {
      const request = {
        unionSeq,
        postFormSeqs,
      };

      deletePostDocument(request, {
        onSuccess: () => {
          snackbar('서식 삭제에 성공하였습니다.', {
            color: 'success',
          });
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      });
    },
    [deletePostDocument, snackbar, unionSeq],
  );

  const handleSubmitReceiverAddition = useCallback(
    (receiver: PostReceiverAdditionValues) => {
      const { phone, name, address, detailAddress, zipNo } = receiver;

      setRows((prev) => [
        ...prev,
        {
          ...POST_REGISTER_DEFAULT_VALUE,
          mainPhone: {
            phoneNo: phone,
            isContactAllowed: true,
          },
          name: {
            name,
            nameClass: '',
          },
          postAddress: {
            address,
            detailAddress,
            zipNo,
          },
          unionRegisterSeq: 0,
          id: `added-${prev.length + 1}`,
        },
      ]);
    },
    [],
  );

  const handleSubmitPostDocumentSelection = useCallback(
    (upcomingFiles: File[]) => {
      setIsNewFileUpdate(true);
      setIsPostPreviewChecked(false);
      setFiles((prevFiles) => [...prevFiles, ...upcomingFiles]);
    },
    [],
  );

  const handleSubmitPostSend = useCallback(() => {
    const request = {
      unionSeq,
      ...formValues,
    };

    sendPost(request, {
      onSuccess: () => {
        snackbar('우편발송 요청이 완료되었습니다.', {
          color: 'success',
        });

        resetPageState();
      },
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  }, [formValues, resetPageState, sendPost, snackbar, unionSeq]);

  const handleSubmitPostStepper = useCallback(
    (data: CreatePostSendBody) => {
      const request = {
        unionSeq,
        ...data,
      };

      sendPost(request, {
        onSuccess: () => {
          snackbar('우편발송 요청이 완료되었습니다.', {
            color: 'success',
          });

          resetPageState();
          closeModal('stepper');
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      });
    },
    [closeModal, resetPageState, sendPost, snackbar, unionSeq],
  );

  const { columns } = useCreateGridColumns({
    columns: POST_REGISTER_COLUMNS,
    handlers: {
      onClickUnionMember: handleOpenUnionInfoModal,
      onClickAddress: handleChangeRegisterAddress,
    },
  });

  useEffect(() => {
    if (groups)
      setSelectedGroupSeq(String(groups?.data?.[0].unionRegisterGroupSeq));
  }, [groups]);

  useEffect(() => {
    setRows(
      sendersReturnData?.data?.map((row) => ({
        ...row,
        id: row.unionRegisterSeq,
        detailAddressInput: '',
      })) || [],
    );
  }, [sendersReturnData?.data]);

  if (isError || !unionSeq) {
    throw error;
  }

  return (
    <>
      <Tabs
        color="neutral"
        selectedTabVariant="plain"
        selectedTabColor="primary"
        tabIndicatorInset
        defaultValue={0}
        value={0}
      >
        <TabList disableUnderline>
          {POST_TABS.map(({ label, path }, index) => (
            <Tab
              key={`tab_${path}`}
              component={Link}
              value={index}
              href={`${pageContext?.unionBasename}${path}`}
            >
              {label}
            </Tab>
          ))}
        </TabList>

        <TabPanel value={0}>
          <Stack gap={6}>
            <Stack gap={2}>
              <Stack gap={1.75}>
                <Stack direction="row" alignItems="center" flex={1} gap={1}>
                  <Select
                    value={selectedGroupSeq}
                    onChange={(_, value) =>
                      setSelectedGroupSeq(value as string)
                    }
                    sx={{ width: '432px' }}
                  >
                    {groups?.data?.map(({ unionRegisterGroupSeq, name }) => (
                      <Option
                        key={`group_option_${unionRegisterGroupSeq}`}
                        value={String(unionRegisterGroupSeq)}
                      >
                        {name}
                      </Option>
                    ))}
                  </Select>

                  <Button
                    variant="soft"
                    onClick={() => openModal('recipientAddition')}
                  >
                    수신자 개별 등록
                  </Button>
                </Stack>

                <Search
                  defaultValues={DEFAULT_SEARCH_PARAMS}
                  onSubmit={handleSearchParams}
                  onReset={handleSearchReset}
                >
                  <Grid container gap={2}>
                    <Grid xs={12} maxWidth={200}>
                      <Search.Field>
                        <TextField
                          label="연번"
                          name="unionRegisterNo"
                          placeholder='숫자 또는 "-"입력'
                          validateOptions={{
                            maxLength: 11,
                            regex: /^(?!.*--)[0-9-]*$/,
                          }}
                          fullWidth
                        />
                      </Search.Field>
                    </Grid>

                    <Grid xs={12} maxWidth={200}>
                      <Search.Field>
                        <TextField
                          label="조합원 이름"
                          name="name"
                          placeholder="조합원 이름 입력"
                          slotProps={{
                            input: { maxLength: 30 },
                          }}
                          fullWidth
                        />
                      </Search.Field>
                    </Grid>
                  </Grid>
                </Search>

                <Stack flexDirection="row" gap={1}>
                  <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                    조회된 목록{' '}
                    <Typography color="primary">
                      {commaizeNumber(rows.length)}
                    </Typography>
                  </Typography>

                  {rowSelectionModel.length > 0 && (
                    <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                      선택{' '}
                      <Typography color="primary">
                        {commaizeNumber(rowSelectionModel.length)}
                      </Typography>
                    </Typography>
                  )}
                </Stack>
              </Stack>

              <Stack height={442}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  loading={isPending}
                  rowSelectionModel={rowSelectionModel}
                  onRowSelectionModelChange={handleChangeRowSelection}
                  processRowUpdate={handleProcessRowUpdate}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </Stack>
            </Stack>

            <Stack gap={2}>
              <PostSenderForm
                files={files}
                control={control}
                setValue={setValue}
                postTypeGroup={postTypeCodeGroup?.items}
                flexTypeGroup={flexTypeCodeGroup?.items}
                colorTypeGroup={colorTypeCodeGroup?.items}
                onChangeFiles={handleChangeFiles}
              />

              <Stack direction="row" justifyContent="flex-end" gap={1}>
                <Button
                  variant="outlined"
                  color="neutral"
                  disabled={files.length === 0}
                  onClick={handleOpenDocumentPreviewModal}
                  loading={isFileUploading}
                >
                  미리보기
                </Button>
                <Button
                  variant="outlined"
                  color="neutral"
                  onClick={() => openModal('documentSelection')}
                >
                  문서서식
                </Button>
                <Button
                  disabled={!isValid || files.length === 0}
                  onClick={handleOpenPostStepper}
                  loading={isFileUploading}
                >
                  우편발송
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </TabPanel>
      </Tabs>

      {modals.unionInfo && (
        <UnionRegisterInfoDetailModal
          params={{
            unionSeq,
            unionRegisterSeq: selectedUnionSeq,
          }}
          open={modals.unionInfo}
          onClose={() => {
            setSelectedUnionSeq(0);
            closeModal('unionInfo');
          }}
        />
      )}

      {modals.recipientAddition && (
        <PostReceiverAdditionModal
          open={modals.recipientAddition}
          onClose={() => closeModal('recipientAddition')}
          onSubmit={handleSubmitReceiverAddition}
        />
      )}

      {modals.documentPreview && latestUploadFile && (
        <PostDocumentPreviewModal
          fileInfo={latestUploadFile}
          open={modals.documentPreview}
          loading={isFileUploading}
          onClose={() => closeModal('documentPreview')}
          onSubmit={handleCreatePostDocument}
        />
      )}

      {modals.documentSelection && (
        <PostDocumentSelectionModal
          unionSeq={unionSeq}
          open={modals.documentSelection}
          onClose={() => closeModal('documentSelection')}
          onDelete={handleDeletePostDocument}
          onSubmit={handleSubmitPostDocumentSelection}
        />
      )}

      {modals.balance && (
        <PostBalanceModal
          unionSeq={unionSeq}
          formValues={formValues}
          open={modals.balance}
          onClose={() => closeModal('balance')}
          onSubmit={handleSubmitPostSend}
        />
      )}

      {modals.stepper && (
        <StepperDialog
          title="우편 발송"
          defaultLastButtonText="우편 발송"
          defaultValues={formValues}
          open={modals.stepper}
          onClose={() => closeModal('stepper')}
        >
          <StepperDialog.Step name="미리보기">
            <PostDocumentPreviewStep unionSeq={unionSeq} />
          </StepperDialog.Step>
          <StepperDialog.Step name="비용 조회">
            <PostBalanceStep
              unionSeq={unionSeq}
              isSendPostPending={isPostSending}
              onSubmit={handleSubmitPostStepper}
            />
          </StepperDialog.Step>
        </StepperDialog>
      )}
    </>
  );
};

const postsPage: CustomRouteObject = {
  path: '/unions/:unionSeq/union-management/posts',
  children: [{ index: true, element: <PostPage /> }, postRecordPage],
  handle: {
    getTitle: () => '우편 발송',
    getMenuCode: () => 'M0405',
  },
};

export default postsPage;
