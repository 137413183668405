import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  FormControl,
  FormLabel,
  Select,
  Option,
  Pagination,
  Grid,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useReadElectronicConsentCertificates } from 'apis/electronic-consents/certificate/apis';
import { ELECTRONIC_CONSENT_CERTIFICATE_COLUMNS } from 'apis/electronic-consents/certificate/fixture';
import {
  CertificateExtensionBody,
  useCertificateExtensionMutation,
} from 'apis/meet/certificate/api';
import { ElectronicConsentCertificate } from 'apis/types/consent';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import ExtensionModal from 'components/pages/consent-management/certifications/modals/ExtensionModal';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns_legacy';
import useFeedback from 'hooks/useFeedback';
import useQueryControls from 'hooks/useQueryControls';
import electronicConsentCertificationDetail from 'pages/main/consent-management/certifications/detail';
import IndexTemplate from 'templates/IndexTemplate';
import { commaizeNumber } from 'utils/format';

const defaultSearchParams = {
  searchTitle: '',
  searchTemplateName: '',
  searchProgressStatus: '',
};

const CertificationPage = () => {
  const { snackbar } = useFeedback();

  const navigate = useNavigate();
  const params = useParams();
  const unionSeq = Number(params.unionSeq);

  const { getGroupCode } = useCommonCodes();

  const [showExtensionModal, setShowExtensionModal] = useState(false);

  const [selectedData, setSelectedData] =
    useState<ElectronicConsentCertificate>();

  const [selectedId, setSelectedId] = useState(0);

  const {
    searchState,
    setSearchState,
    paginationState,
    setPaginationState,
    initState,
  } = useQueryControls({
    defaultSearchParams: { searchUnionSeq: unionSeq, ...defaultSearchParams },
    initSearchParams: { searchUnionSeq: unionSeq, ...defaultSearchParams },
  });

  const { data, isLoading, isError, error, refetch } =
    useReadElectronicConsentCertificates(searchState);

  const { mutate } = useCertificateExtensionMutation(selectedId);

  const handleConfirmModal = useCallback(
    (consentSeq: number) => {
      navigate(`./${consentSeq}`);
    },
    [navigate],
  );

  const handleExtensionClick = useCallback(
    (consentSeq: number) => {
      setShowExtensionModal(true);
      setSelectedId(consentSeq);

      const selectedData = data?.data.find((row) => {
        return row.consentSeq === consentSeq;
      });

      if (selectedData) setSelectedData(selectedData);
    },
    [data?.data],
  );

  const handleOnSubmit = (formData: CertificateExtensionBody) => {
    mutate(formData, {
      onSuccess: () => {
        snackbar('문서보간 기간 연장 신청이 완료되었습니다.', {
          color: 'success',
        });
        refetch();
      },
      onError: (e) => {
        snackbar(e.response?.data.message ?? e.message, {
          color: 'danger',
        });
      },
    });
  };

  const rows = useMemo(() => data?.data || [], [data?.data]);

  const handlers = useMemo(
    () => ({
      onClickName: handleConfirmModal,
      onClickExtension: handleExtensionClick,
    }),
    [handleConfirmModal, handleExtensionClick],
  );

  const { columns } = useCreateGridColumns({
    columns: ELECTRONIC_CONSENT_CERTIFICATE_COLUMNS,
    handlers,
  });

  const consentProgressStatusCodeGroup = useMemo(
    () => ({
      groupCode: 'ELECTRONIC_CONSENT_PROGRESS_STATUS',
      items:
        getGroupCode('ELECTRONIC_CONSENT_PROGRESS_STATUS')?.items.filter(
          ({ name }) => name !== '진행전',
        ) ?? [],
    }),
    [getGroupCode],
  );
  // const documentStorageStatusCodeGroup = useMemo(
  //   () => getGroupCode('DOCUMENT_STORAGE_STATUS'),
  //   [getGroupCode],
  // );

  const totalElements = data?.pagination?.totalElements || 0;
  const totalDataCount = data?.pagination?.totalDataCount || 0;
  const pageTotalCount =
    totalElements && Math.ceil(totalElements / paginationState.pageSize);

  if (isError) throw error;

  return (
    <>
      <IndexTemplate
        SearchComponent={
          <Search
            values={searchState}
            onSubmit={setSearchState}
            onReset={initState}
          >
            <Grid container gap={2}>
              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    placeholder="제목을 입력해 주세요."
                    label="전자 동의서 제목"
                    name="searchTitle"
                    fullWidth
                    validateOptions={{
                      maxLength: 50,
                    }}
                  />
                </Search.Field>
              </Grid>

              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    placeholder="제목을 입력해 주세요."
                    label="전자 동의서 유형"
                    name="searchTemplateName"
                    fullWidth
                    validateOptions={{
                      maxLength: 50,
                    }}
                  />
                </Search.Field>
              </Grid>

              <Grid xs={12} maxWidth={200}>
                <FormControl sx={{ width: '100%' }}>
                  <FormLabel>동의서 상태</FormLabel>
                  <Search.SelectField>
                    <Select sx={{ width: '100%' }} name="searchProgressStatus">
                      <Option value="">전체</Option>

                      {consentProgressStatusCodeGroup?.items.map((item) => (
                        <Option key={item.code} value={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Search.SelectField>
                </FormControl>
              </Grid>
            </Grid>
          </Search>
        }
        TopPanelComponent={
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(totalDataCount)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(totalElements)}
              </Typography>
            </Typography>
          </Stack>
        }
        IndexComponent={
          <DataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowId={(row) => row.consentSeq}
            disableRowSelectionOnClick
            pagination
            paginationModel={paginationState}
            onPaginationModelChange={setPaginationState}
          />
        }
        PaginationComponent={
          <Pagination
            color="neutral"
            variant="plain"
            size="md"
            orientation="horizontal"
            showFirstButton
            showLastButton
            hidePrevButton={false}
            hideNextButton={false}
            count={pageTotalCount}
            page={paginationState.page + 1}
            onChange={(_, page) => {
              page &&
                setPaginationState({
                  page: page - 1,
                  pageSize: paginationState.pageSize,
                });
            }}
          />
        }
      />

      <ExtensionModal
        name={selectedData?.title}
        endAt=""
        open={showExtensionModal}
        onClose={setShowExtensionModal}
        onSubmit={handleOnSubmit}
      />
    </>
  );
};

const electronicConsentCertification = {
  path: '/unions/:unionSeq/consent-management/certification',
  children: [
    {
      index: true,
      element: <CertificationPage />,
      handle: {
        getTitle: () => '전자 동의 증명서 발급',
      },
    },
    electronicConsentCertificationDetail,
  ],
  handle: {
    getTitle: () => '전자 동의 증명서 발급',
    getMenuCode: () => 'M1202',
  },
};

export default electronicConsentCertification;
