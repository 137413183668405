import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  CircularProgress,
  DateTimePicker,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalDialog,
  Option,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Stack,
  Switch,
  Textarea,
  TextField,
  Typography,
} from '@wooriga/design-system';
import dayjs from 'dayjs';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  useCreateElectronicConsentProgressStatus,
  useUpdateElectronicConsent,
  useReadElectronicConsent,
} from 'apis/electronic-consents/apis';
import { useReadElectronicConsentTemplates } from 'apis/electronic-consents/template/apis';
import ElectronicConsentProgressStatusChip from 'components/Chips/ElectronicConsentProgressStatusChip';
import useFeedback from 'hooks/useFeedback';

const schema = yup.object({
  templateSeq: yup.number().required().min(1, '동의서 유형을 선택해 주세요.'),
  title: yup.string().required('제목을 입력해 주세요.'),
  content: yup.string().optional(),
  startedAt: yup.string().required('제출 시작일시를 설정해 주세요.'),
  endedAt: yup.string().when('isEndDateUnknown', {
    is: (value: boolean) => value === false,
    then: (schema) => schema.required('제출 종료일시를 설정해 주세요.'),
  }),
  isSignatureRequired: yup.boolean().required(),
  isActiveCondition: yup.boolean().required(),
  isEndDateUnknown: yup.boolean().required(),
  consentCondition: yup
    .number()
    .required('동의율을 입력해 주세요.')
    .min(1, '최소 1%부터 입력 가능합니다.')
    .max(100, '최대 100%까지 입력 가능합니다.'),
});

export interface ConsentInfoModalProps {
  unionSeq: number;
  consentSeq: number;
  open: boolean;
  onClose: (show: false) => void;
}

const ConsentInfoModal = ({
  unionSeq,
  consentSeq,
  open,
  onClose,
}: ConsentInfoModalProps) => {
  const { confirmDialog, snackbar } = useFeedback();
  const queryClient = useQueryClient();

  const {
    data: consent,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useReadElectronicConsent({ consentSeq });
  const { data: templates } = useReadElectronicConsentTemplates({
    searchUnionSeq: unionSeq,
  });

  const { mutate: updateElectronicConsent } = useUpdateElectronicConsent();
  const { mutate: completeElectronicConsent } =
    useCreateElectronicConsentProgressStatus();

  const { control, handleSubmit, watch, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      templateSeq: 0,
      title: '',
      content: '',
      startedAt: '',
      endedAt: '',
      isSignatureRequired: true,
      isActiveCondition: false,
      isEndDateUnknown: false,
      consentCondition: 100,
    },
  });

  const handleAgreementEnd = () => {
    confirmDialog('동의서 제출이 불가능해지며, 복구할 수 없습니다.', {
      message: '전자 동의서를 종료하시겠습니까?',
      onConfirm: () => {
        completeElectronicConsent(
          { consentSeq, progressStatus: 'COMPLETED' },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: [`/v2/electronic-consents/${consentSeq}`],
              });
              onClose(false);
            },
            onError: (error) => {
              snackbar(error?.response?.data.message ?? error.message, {
                color: 'danger',
              });
            },
          },
        );
      },
    });
  };

  const validateSubmit = handleSubmit((data) => {
    updateElectronicConsent(
      {
        ...data,
        content: data.content ?? '',
        endedAt: !isEndDateUnknown ? (data.endedAt ?? '') : null,
        unionSeq,
        consentSeq,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [`/v2/electronic-consents/${consentSeq}`],
          });
          onClose(false);
        },
        onError: (error) => {
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  });

  const { startedAt, isEndDateUnknown, isActiveCondition, templateSeq } =
    watch();

  const [
    isTemplateDisabled,
    isConditionDisabled,
    isEndAtDisabled,
    isSignatureDisabled,
    isOtherDisabled,
  ] = useMemo(() => {
    const status = consent?.data.progressStatus;
    const foundTemplate = templates?.data.find(
      (template) => template.templateSeq === templateSeq,
    );

    return [
      status !== 'BEFORE',
      status === 'COMPLETED',
      status === 'COMPLETED',
      status !== 'BEFORE' || foundTemplate?.signatureUsage !== 'OPTIONAL',
      status !== 'BEFORE',
    ];
  }, [consent?.data, templates, templateSeq]);

  const minEndDate = useMemo(
    () => dayjs(startedAt).add(1, 'day').toString(),
    [startedAt],
  );

  if (isError) throw error;

  useEffect(() => {
    if (consent?.data) {
      reset({
        ...consent.data,
        isEndDateUnknown: !consent.data.endedAt,
        templateSeq: consent.data.template.templateSeq,
      }); // React Query의 데이터로 폼 값 설정
    }
  }, [consent?.data, reset]);

  return (
    <Modal
      component="form"
      open={open}
      onClose={onClose}
      onReset={() => onClose(false)}
      onSubmit={validateSubmit}
    >
      <ModalDialog>
        <DialogTitle sx={{ direction: 'row', gap: 2 }}>
          <Typography
            level="title-lg"
            fontSize="lg"
            fontWeight="xl"
            lineHeight="xl"
          >
            동의서 정보
          </Typography>
          <ElectronicConsentProgressStatusChip
            code={consent?.data.progressStatus}
            disabledIcon
          />
        </DialogTitle>

        {isLoading && (
          <Stack
            width={509}
            height={509}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Stack>
        )}
        {isSuccess && (
          <DialogContent sx={{ pt: 1, gap: 3 }}>
            <Stack direction="row" gap={4}>
              <Controller
                name="templateSeq"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error}>
                    <FormLabel>전자 동의서 유형 선택</FormLabel>
                    <Skeleton loading={isLoading} variant="rectangular">
                      <Select
                        disabled={isTemplateDisabled}
                        placeholder="전자 동의서 유형 선택"
                        value={String(field.value)}
                        onChange={(_, value) => field.onChange(Number(value))}
                        sx={{ width: 268 }}
                      >
                        {templates?.data.map(({ templateSeq, name }) => (
                          <Option
                            key={`template_option_${templateSeq}`}
                            value={`${templateSeq}`}
                          >
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </Skeleton>
                    {error?.message && (
                      <FormHelperText>{error.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />

              <Controller
                name="isSignatureRequired"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    disabled={isSignatureDisabled}
                    label="인증서 전자서명 여부"
                    orientation="horizontal"
                    value={String(field.value)}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      field.onChange(event.target.value === 'true');
                    }}
                  >
                    <Radio label="활성화" value="true" />
                    <Radio label="비활성화" value="false" />
                  </RadioGroup>
                )}
              />
            </Stack>

            <Stack direction="row">
              <Controller
                name="consentCondition"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="number"
                    label="공유자 동의 조건"
                    disabled={isConditionDisabled || !isActiveCondition}
                    onChange={(event) =>
                      field.onChange(event.currentTarget.valueAsNumber)
                    }
                    validateOptions={{
                      regex: /^\d+(\.\d{1,3})?$/,
                    }}
                    slotProps={{ input: { step: 0.1, min: 1, max: 100 } }}
                    endDecorator={<Typography>%</Typography>}
                    sx={{ width: 100 }}
                    error={!!error}
                    helperText={error?.message ?? ''}
                  />
                )}
              />

              <Controller
                name="isActiveCondition"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={value}
                    disabled={isConditionDisabled}
                    onChange={(event) => onChange(event.currentTarget.checked)}
                    sx={{ alignSelf: 'flex-start' }}
                  />
                )}
              />
            </Stack>

            <Stack gap={1}>
              <Stack direction="row" gap={2} flexWrap="wrap">
                <Controller
                  name="startedAt"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl sx={{ flex: 1 }} error={!!error}>
                      <FormLabel>제출 시작일시*</FormLabel>
                      <DateTimePicker
                        {...field}
                        disabled={isOtherDisabled}
                        disablePast
                      />
                      {error?.message && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />

                <Controller
                  name="endedAt"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl sx={{ flex: 1 }} error={!!error}>
                      <FormLabel>제출 종료일시</FormLabel>
                      <DateTimePicker
                        {...field}
                        disabled={isEndAtDisabled || isEndDateUnknown}
                        minDateTime={minEndDate}
                      />
                      {error?.message && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Stack>

              <Stack direction="row" alignSelf="flex-end" gap={1}>
                <Typography>종료일시 미정</Typography>
                <Controller
                  name="isEndDateUnknown"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      disabled={isEndAtDisabled}
                      onChange={(event) =>
                        field.onChange(event.currentTarget.checked)
                      }
                    />
                  )}
                />
              </Stack>
            </Stack>

            <Stack gap={1}>
              <Controller
                name="title"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    required
                    label="전자 동의서 제목"
                    placeholder="제목을 입력하세요."
                    disabled={isOtherDisabled}
                    error={!!error}
                    helperText={error?.message ?? ''}
                    validateOptions={{ maxLength: 50 }}
                  />
                )}
              />

              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>전자 동의서 내용</FormLabel>
                    <Textarea
                      {...field}
                      variant="outlined"
                      color="neutral"
                      size="md"
                      placeholder="내용을 입력하세요."
                      disabled={isOtherDisabled}
                      sx={{ height: 120 }}
                      validateOptions={{ maxLength: 300 }}
                    />
                  </FormControl>
                )}
              />
            </Stack>
          </DialogContent>
        )}

        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Stack direction="row" gap={1}>
            <Button type="reset" variant="outlined" color="neutral">
              취소
            </Button>
            <Button
              disabled={consent?.data.progressStatus === 'COMPLETED'}
              type="submit"
            >
              확인
            </Button>
          </Stack>

          {consent?.data.progressStatus === 'IN_PROGRESS' && (
            <Button color="danger" onClick={handleAgreementEnd}>
              동의서 종료
            </Button>
          )}
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default ConsentInfoModal;
