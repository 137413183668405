import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EventIcon from '@mui/icons-material/Event';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import VisibilityIcon from '@mui/icons-material/Visibility';
import type { Theme } from '@mui/material/styles/createTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatDate } from '@wooriga/common-utils';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useMemo } from 'react';

import { ElectronicConsent } from 'apis/types/consent';
import ElectronicConsentProgressStatusChip from 'components/Chips/ElectronicConsentProgressStatusChip';
export interface ConsentInfoPanelProps {
  data: {
    consent: ElectronicConsent | undefined;
  };
  loading?: boolean;
  onClickInfo: () => void;
  onClickPreview: () => void;
  onClickClipboard: () => void;
}

const ConsentInfoPanel = ({
  data,
  loading = false,
  onClickInfo,
  onClickPreview,
  onClickClipboard,
}: ConsentInfoPanelProps) => {
  const { consent } = data;

  const isAboveMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const isBelowLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xl'),
  );

  const isBetweenMdAndLg = useMemo(
    () => isAboveMd && isBelowLg,
    [isAboveMd, isBelowLg],
  );

  return (
    <Card sx={{ height: '100%', minHeight: 298, p: 5 }}>
      <CardContent sx={{ justifyContent: 'space-between' }}>
        <Stack gap={2}>
          <Skeleton
            loading={loading}
            variant="rectangular"
            width={60}
            height={22}
            sx={{ borderRadius: 'lg' }}
          >
            <ElectronicConsentProgressStatusChip
              code={consent?.progressStatus ?? ''}
            />
          </Skeleton>

          <Stack gap={1}>
            <Typography fontSize="xl3" fontWeight="xl" lineHeight="xs">
              <Skeleton
                loading={loading}
                variant="rectangular"
                width={240}
                height={40}
              >
                {consent?.title}
              </Skeleton>
            </Typography>
            <Typography
              fontSize="md"
              fontWeight="md"
              lineHeight="md"
              textColor="neutral.500"
              startDecorator={<EventIcon />}
            >
              <Skeleton
                loading={loading}
                variant="rectangular"
                width={180}
                height={24}
              >
                {formatDate(consent?.startedAt)} ~
                {consent?.endedAt ? formatDate(consent?.endedAt) : '미정'}
              </Skeleton>
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" gap={1}>
          {!isBetweenMdAndLg && (
            <>
              <Button
                startDecorator={<ReceiptLongIcon />}
                onClick={onClickInfo}
              >
                전자 동의서 정보
              </Button>
              <Button
                variant="outlined"
                color="neutral"
                startDecorator={<VisibilityIcon />}
                onClick={onClickPreview}
              >
                서식 미리보기
              </Button>
              <Button
                variant="outlined"
                color="neutral"
                startDecorator={<ContentCopyIcon />}
                onClick={onClickClipboard}
              >
                전자 동의서 URL 복사
              </Button>
            </>
          )}
          {isBetweenMdAndLg && (
            <Grid container spacing={1}>
              <Grid xs={6}>
                <Button
                  variant="outlined"
                  color="neutral"
                  fullWidth
                  startDecorator={<VisibilityIcon />}
                  onClick={onClickPreview}
                >
                  서식 미리보기
                </Button>
              </Grid>

              <Grid xs={6}>
                <Button
                  variant="outlined"
                  color="neutral"
                  fullWidth
                  startDecorator={<ContentCopyIcon />}
                  onClick={onClickClipboard}
                >
                  전자 동의서 URL 복사
                </Button>
              </Grid>

              <Grid xs={12}>
                <Button
                  fullWidth
                  startDecorator={<ReceiptLongIcon />}
                  onClick={onClickInfo}
                >
                  전자 동의서 정보
                </Button>
              </Grid>
            </Grid>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ConsentInfoPanel;
