/**
 * 전자 동의서 양식 관련 APIs
 *
 * HTTP Method: GET
 */

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { ElectronicConsentTemplate } from 'apis/types/consent';
import { ApiError, ApiResponseData } from 'types/api';

// 전자 동의서 양식 목록 조회
type UseReadElectronicConsentTemplatesResult = UseQueryResult<
  ApiResponseData<ElectronicConsentTemplatesResponse, true>,
  ApiError
>;

export type ElectronicConsentTemplatesResponse = ElectronicConsentTemplate[];
export interface ElectronicConsentTemplatesParams {
  searchUnionSeq?: number;
  searchTemplateName?: string;
}

export const useReadElectronicConsentTemplates = (
  params: ElectronicConsentTemplatesParams,
): UseReadElectronicConsentTemplatesResult => {
  return useQuery({
    queryKey: [`/v2/electronic-consent-templates`, params],
  });
};

// 전자 동의서 양식 상세 조회
type UseReadElectronicConsentTemplateResult = UseQueryResult<
  AxiosResponse<ElectronicConsentTemplateResponse>,
  AxiosError
>;
export interface ElectronicConsentTemplateResponse {
  // 최소한의 필요로 하는 Property만 Type 추가
  pages: { id: string; content: { backgroundImage: { src: string } } }[];
}

export const useReadElectronicConsentTemplate = (
  templateUrl: string,
): UseReadElectronicConsentTemplateResult => {
  const templatePath = templateUrl.split('/').slice(3).join('/');

  return useQuery({
    queryKey: ['/v2/electronic-consent-template', templatePath],
    queryFn: async () => await axios.get(templateUrl),
    enabled: !!templateUrl,
  });
};
