// import { delay, http } from 'msw';
import { setupWorker } from 'msw/browser';

// import consent from 'apis/agreements/mocks';
import electronicConsent from 'apis/electronic-consents/mocks';
// import archiveList from 'apis/archives/mocks';
// import auth from 'apis/auth/mocks';
// import chargeList from 'apis/charges/mocks';
// import common from 'apis/common/mocks';
// import counsel from 'apis/counsel/mocks';
// import dataIssuance from 'apis/document-issuance/mocks';
// import form from 'apis/form/mocks';
// import member from 'apis/member/mocks';
// import generalMeetingHistory from 'apis/meet/mocks';
// import message from 'apis/message/mocks';
// import post from 'apis/post/mocks';
// import avaliableMenu from 'apis/union/member/available-menu/mocks';
// import unionRegister from 'apis/union/register/mocks';
// import unionServiceInfo from 'apis/union/serviceInfo/mocks';
// import unionRegisterLocalAddressRights from 'apis/union/register/local-address-rights/mocks';
// import managerList from 'kwon/union-info/mocks';
// import passthrough from 'lim/_mocks/common';
// import addressGroupDetail from 'lim/address-group/detail/mocks';
// import addressGroup from 'lim/address-group/mocks';
// import authManagement from 'lim/auth/mocks';
// import generalMeetingHistoryDetail from 'lim/generalMeetingHistoryDetail/mocks';
// import generalMeetingHistoryVoterEdit from 'lim/generalMeetingHistoryDetail/voterEdit/mocks';
// import meetCertification from 'lim/meetCertification/mocks';
// import meetOpen from 'lim/meetOpen/mocks';
// import meetPromotion from 'lim/meetPromotion/mocks';
// import accountDetail from 'lim/member/detail/mocks';
// import account from 'lim/member/mocks';

// const globalHandler = [
//   http.all('*', async () => {
//     await delay(1000);
//   }),
// ];

const worker = setupWorker(...electronicConsent);
//   // ...globalHandler,
//   // ...passthrough,
//   // ...unionRegister,
//   ...account,
//   ...accountDetail,
//   ...addressGroup,
//   ...addressGroupDetail,
//   ...authManagement,
//   // ...auth,
//   // ...member,
//   // ...unionServiceInfo,
//   // ...unionRegisterLocalAddressRights,
//   // ...avaliableMenu,
//   ...generalMeetingHistory,
//   ...generalMeetingHistoryDetail,

//   ...generalMeetingHistoryVoterEdit,
//   ...meetPromotion,
//   ...meetCertification,
//   ...meetOpen,

//   // ...common,
//   ...message,
//   ...dataIssuance,
//   ...post,
//   ...form,
//   ...counsel,
//   ...chargeList,
//   ...managerList,
//   ...archiveList,

export const enableMocking = async () => {
  if (import.meta.env.MODE !== 'debug') return;
  return await worker.start({ onUnhandledRequest: 'bypass' });
};
