import UploadFileIcon from '@mui/icons-material/UploadFile';
import styled from '@mui/joy/styles/styled';
import { ColorPaletteProp } from '@mui/joy/styles/types/colorSystem';
import { VariantProp } from '@mui/joy/styles/types/variants';
import useControlled from '@mui/utils/useControlled';
import * as _ from 'lodash-es';
import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';

import Typography from 'design-system/components/dataDisplay/Typography';
import FormControl, {
  FormControlProps,
} from 'design-system/components/inputs/FormControl';
import FormLabel from 'design-system/components/inputs/FormLabel';
import { MIME_TYPES } from 'design-system/components/inputs/upload/constants';
import FileSelect from 'design-system/components/inputs/upload/FileSelect';
import Stack from 'design-system/components/layout/Stack';

export type FileDragDropSlot = 'root' | 'label';

export interface FileDragDropProps extends FormControlProps {
  variant?: VariantProp;
  color?: ColorPaletteProp;
  size?: 'sm' | 'md' | 'lg';
  error?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  id?: string;
  name?: string;
  accept?: string;
  orientation?: 'horizontal' | 'vertical';
  onSelectFiles?: (files: FileList | null) => void;
  onSelectInvalidFiles?: (files: FileList | null) => void;
}

export type FileDragDropOwnerState = FileDragDropProps;

const FileDragDrop = (
  props: FileDragDropProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    variant,
    color,
    size,
    error: errorProp,
    disabled,
    accept,
    fullWidth,
    id: idProp,
    name,
    orientation = 'vertical',
    onSelectFiles,
    onSelectInvalidFiles,
    ...other
  } = props;

  const id = useMemo(
    () => (idProp ? idProp : _.uniqueId('file-dragdrop-')),
    [idProp],
  );

  const [error, setError] = useControlled({
    controlled: errorProp,
    default: false,
    name: 'FileDragDrop',
    state: 'error',
  });

  const ownerState = {
    error,
    disabled,
    variant,
    color,
    size,
    fullWidth,
  };

  const validExtensions = useMemo(() => {
    if (!accept) return null;

    const extensions = accept
      .split(',')
      .map((mimeType) => MIME_TYPES[mimeType.trim()] || mimeType.trim());

    return extensions;
  }, [accept]);

  const acceptMessage = useMemo(() => {
    if (!validExtensions) return '모든 파일을 업로드 가능합니다.';
    return `${validExtensions.join(', ')} 파일만 업로드 가능합니다.`;
  }, [validExtensions]);

  const handleSelectFiles = useCallback(
    (files: FileList | null) => {
      setError(false);
      onSelectFiles?.(files);
    },
    [onSelectFiles, setError],
  );
  const handleSelectInvalidFiles = useCallback(
    (files: FileList | null) => {
      setError(true);
      onSelectInvalidFiles?.(files);
    },
    [onSelectInvalidFiles, setError],
  );

  return (
    <FileDragDropRoot
      component={FormControl}
      disabled={disabled}
      ownerState={ownerState}
      ref={ref}
      {...other}
    >
      <FileSelect
        accept={accept}
        id={id}
        name={name}
        enableDragOver
        onSelectFiles={handleSelectFiles}
        onSelectInvalidFiles={handleSelectInvalidFiles}
        disabled={disabled}
      >
        <FileDragDropLabel
          ownerState={ownerState}
          sx={{
            alignItems: orientation === 'vertical' ? 'flex-start' : 'center',
          }}
        >
          <Stack
            flexWrap="wrap"
            alignItems={orientation === 'vertical' ? 'flex-start' : 'center'}
          >
            <Typography
              startDecorator={<UploadFileIcon />}
              fontSize="lg"
              fontWeight="md"
              lineHeight="lg"
              textColor="inherit"
            >
              파일 끌어와서 올리기
            </Typography>

            <Typography
              fontSize="md"
              fontWeight="md"
              lineHeight="md"
              textColor="inherit"
            >
              {acceptMessage}
            </Typography>

            <Typography
              fontSize="md"
              fontWeight="md"
              lineHeight="md"
              textColor="inherit"
            >
              직접 선택하려면 여기를 누르세요.
            </Typography>
          </Stack>
        </FileDragDropLabel>
      </FileSelect>
    </FileDragDropRoot>
  );
};

const FileDragDropRoot = styled(FormControl, {
  name: 'JoyFileDragDrop',
  slot: 'root',
})<{ ownerState: FileDragDropOwnerState }>(({ ownerState: { fullWidth } }) => ({
  width: 348,
  ...(fullWidth && { width: '100%' }),
}));

const FileDragDropLabel = styled(FormLabel, {
  name: 'JoyFileDragDrop',
  slot: 'label',
})<{ ownerState: FileDragDropOwnerState; dragOver?: boolean }>(
  ({
    theme,
    ownerState: {
      fullWidth,
      variant = 'outlined',
      size = 'sm',
      color = 'neutral',
      disabled,
      error,
    },
    dragOver,
  }) => ({
    display: 'flex',
    width: '100%',
    heigth: '100%',
    flexDirection: 'column',
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.surface,
    borderRadius: theme.radius[size],
    ...theme.variants[variant][color],

    ...(variant !== 'solid' && { color: theme.palette.neutral[500] }),

    ...(fullWidth && { width: '100%' }),
    '--Input-focused': 0,
    '--Input-focusedThickness': 'var(--joy-focus-thickness)',
    '--Input-focusedHighlight': 'var(--joy-palette-primary-500)',

    ...(dragOver && { '--Input-focused': 1 }),
    ...(error && {
      '--Input-focused': 1,
      '--Input-focusedHighlight': 'var(--joy-palette-danger-500)',
    }),

    ...(disabled && {
      color: theme.palette.neutral[400],
    }),

    '&::before': {
      boxSizing: 'border-box',
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      borderRadius: 'inherit',
      margin: 'clac(var(--variant-borderWidth, 0px) * -1)',
      boxShadow:
        'var(--Input-focusedInset, inset) 0 0 0 calc(var(--Input-focused)* var(--Input-focusedThickness)) var(--Input-focusedHighlight)',
    },
  }),
);

export default forwardRef<HTMLDivElement, FileDragDropProps>(FileDragDrop);
