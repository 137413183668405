import {
  Box,
  Button,
  Option,
  DatePicker,
  FormControl,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Textarea,
  Typography,
  Grid,
  FileDragDrop,
  FileList,
  Snackbar,
  SnackbarClose,
  //CheckOption,
} from '@wooriga/design-system';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useArchiveDetailQuery,
  useArchiveUpdateMutation,
} from 'apis/archives/apis';
import { useFileUploadMutation } from 'apis/common/apis';
import { ArchiveUpdate } from 'apis/types/archive';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import useLayoutContext from 'hooks/useLayoutContext';
import { CustomRouteObject } from 'types/route';

export interface Files {
  fileSeq: number;
  originName: string;
}

const ArchvicesUpdatePage = () => {
  const navigate = useNavigate();
  const { id: archiveSeq } = useParams<{ id: string }>();
  const { pageContext } = useLayoutContext();
  const memberUnionInfo = pageContext?.memberUnionInfo;

  const {
    data: archiveData,
    isError,
    error,
    isLoading,
  } = useArchiveDetailQuery({
    unionSeq: memberUnionInfo?.unionSeq || 0,
    archiveSeq: Number(archiveSeq),
  });

  const { getGroupCode } = useCommonCodes();

  const codeGroup = useMemo(
    () => getGroupCode('ARCHIVE_CATEGORY'),
    [getGroupCode],
  );

  const [currentFileList, setCurrentFileList] = useState<Files[]>([]);
  const [toastInfo, setToastInfo] = useState({
    success: true,
    show: false,
    content: '',
  });

  const { mutate: updateArchive } = useArchiveUpdateMutation();
  const { mutate: uploadFiles } = useFileUploadMutation();

  const methods = useForm<ArchiveUpdate>({
    defaultValues: {
      title: '',
      content: '',
      category: '',
      isPublic: false,
      fileSeqs: [],
    },
  });

  const handleSelectFiles = (upcomingFiles: FileList | null) => {
    if (!upcomingFiles) {
      return;
    }
    const fileList = new DataTransfer();
    fileList.items.add(upcomingFiles[0]);

    uploadFiles(
      {
        divisionCode: 'UNION_BOARD',
        files: fileList.files,
      },
      {
        onSuccess: (response) => {
          if (response.data) {
            if (!currentFileList) {
              setCurrentFileList([response.data[0]]);
            } else {
              response.data[0].fileSeq &&
                setCurrentFileList([...currentFileList, response.data[0]]);
            }
          }
        },
        onError: (error) => {
          console.log('error', error);
        },
      },
    );
  };

  useEffect(() => {
    if (archiveData?.data) {
      // fileSeq만 거르기
      // const filterdFileSeqs =
      //   archiveData?.data?.files?.map((item) => item.fileSeq) || [];

      methods.reset({
        title: archiveData?.data?.title,
        content: archiveData?.data?.content,
        category: archiveData?.data?.category,
        isPublic: archiveData?.data?.isPublic,
        // fileSeqs: filterdFileSeqs,
      });
      if (archiveData?.data?.files) {
        setCurrentFileList(archiveData?.data?.files);
      }
    }
  }, []);

  if (isError) throw error;

  const handleSubmit = methods.handleSubmit((formData) => {
    formData.fileSeqs = currentFileList.map((item) => item.fileSeq);
    if (!formData.isPublic) {
      formData.isPublic = false;
    }
    updateArchive(
      {
        unionSeq: memberUnionInfo?.unionSeq || 0,
        archiveSeq: Number(archiveSeq),
        ...formData,
      },
      {
        onSuccess: () => {
          navigate('..');
        },
      },
    );
  });

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <FormProvider {...methods}>
      <Stack gap={2} component="form" onSubmit={handleSubmit}>
        <Stack gap={1.75}>
          <Typography level="title-lg">자료실 수정</Typography>
          <Typography level="title-md">자료 정보</Typography>

          <Stack gap={2}>
            <Grid container xs={12} gap={2}>
              <Grid xs={3}>
                <FormControl>
                  <FormLabel>구분</FormLabel>
                  <Select
                    {...methods.register('category', {
                      required: true,
                    })}
                    onChange={(_, value) => {
                      if (value) {
                        value ?? methods.setValue('category', value);
                      }
                    }}
                    defaultValue={archiveData?.data?.category}
                  >
                    {codeGroup?.items.map((item) => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={3}>
                <DatePicker
                  variant="outlined"
                  color="neutral"
                  size="md"
                  label="작성일"
                  readOnly
                  value={
                    archiveData?.data?.createdAt &&
                    new Date(archiveData?.data?.createdAt)
                  }
                />
              </Grid>
              <Grid xs={3}>
                <DatePicker
                  variant="outlined"
                  color="neutral"
                  label="수정일"
                  size="md"
                  readOnly
                  value={
                    archiveData?.data?.createdAt &&
                    new Date(archiveData?.data?.createdAt)
                  }
                />
              </Grid>
              <Grid xs={2}>
                <Box minWidth={200}>
                  <FormControl>
                    <FormLabel>공개여부</FormLabel>
                    <RadioGroup
                      orientation="horizontal"
                      size="sm"
                      {...methods.register('isPublic')}
                    >
                      <Radio
                        value={true}
                        size="md"
                        color="neutral"
                        variant="outlined"
                        label="공개"
                        checked={methods.watch('isPublic')}
                      />
                      <Radio
                        value={false}
                        size="md"
                        color="neutral"
                        variant="outlined"
                        label="비공개"
                        checked={!methods.watch('isPublic')}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Stack>
              <TextField
                label="제목"
                fullWidth
                placeholder="제목 입력"
                {...methods.register('title', {
                  required: true,
                })}
              />
            </Stack>
            <Stack>
              <Typography>내용</Typography>
              <Textarea
                placeholder="내용 입력"
                sx={{
                  height: '422px',
                }}
                {...methods.register('content', {
                  required: true,
                })}
              />
            </Stack>
            {archiveData?.data && archiveData?.data?.fileCount > 0 ? (
              <Stack>
                <Typography>첨부파일</Typography>
                <Box>
                  <FileDragDrop
                    variant="outlined"
                    color="neutral"
                    orientation="horizontal"
                    accept="image/png, image/jpg, image/jpeg"
                    fullWidth
                    onSelectFiles={handleSelectFiles}
                  />
                </Box>
              </Stack>
            ) : null}

            {archiveData?.data && archiveData?.data?.fileCount > 0 ? (
              <Stack>
                <Typography>
                  첨부파일 목록{' '}
                  <Typography color="primary">
                    {currentFileList?.length}
                  </Typography>
                </Typography>
                <Box>
                  <FileList<Files>
                    // {...methods.register('fileSeqs')}
                    variant="outlined"
                    color="neutral"
                    value={currentFileList}
                    labelGetter={(value) => value.originName}
                    fullWidth
                    readOnly={true}
                  />
                </Box>
              </Stack>
            ) : null}
          </Stack>
          <Stack direction="row" alignSelf="end" gap={1}>
            <Button
              component={Link}
              variant="outlined"
              color="neutral"
              size="md"
              href=".."
            >
              목록
            </Button>
            <Button size="md" color="primary" type="submit">
              수정
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Snackbar
        color="danger"
        open={toastInfo.show}
        endDecorator={<SnackbarClose />}
        onClose={() =>
          setToastInfo({
            ...toastInfo,
            show: false,
          })
        }
      >
        {toastInfo.content}
      </Snackbar>
    </FormProvider>
  );
};

const archvicesUpdatePage: CustomRouteObject = {
  path: 'update/:id',
  element: <ArchvicesUpdatePage />,
  handle: {
    getTitle: () => '자료실 상세',
  },
};
export default archvicesUpdatePage;
