import { Chip, GridColDef, Link, Stack } from '@wooriga/design-system';

import { RealAddress } from 'apis/types/common';
import {
  UnionRegisterAgentInfo,
  UnionRegisterInfo,
  UnionRegisterLocalAddressRight,
} from 'apis/types/union';
import { GridColumnsHandlers } from 'types/grid';
import { formatBirth, formatDate, formatTelephone } from 'utils/format';
import { formatCompanyRegistrationNumber } from 'utils/format/company';

export interface UnionRegisterRow
  extends Omit<UnionRegisterInfo, 'localAddresses'> {
  id: string;
  localAddress: UnionRegisterLocalAddressRight;
}
const defaultRowSpanValueGetter = (value: unknown, row: UnionRegisterRow) =>
  row ? `${row.unionRegisterSeq}-${value}` : value;

const noneRowSpanValueGetter = () => null;

export const UNION_REGISTERS_GRID_COLUMNS = ({
  onClickAgentName,
  onClickNotification,
  getCommonCode,
}: GridColumnsHandlers<{
  onClickAgentName: (unionRegisterAgentSeq: number) => void;
  onClickNotification: (unionRegisterSeq: number) => void;
}>) =>
  [
    {
      field: 'managementNo',
      headerName: '관리번호',
      width: 100,
      minWidth: 100,
      rowSpanValueGetter: defaultRowSpanValueGetter,
      renderCell: ({ value, row }) => {
        const { localAddress, unionRegisterSeq } = row;
        const isChangedRegistration =
          localAddress?.registrationAddresses?.filter(
            (registrationAddress) =>
              registrationAddress.registrationTrace.isChanged,
          ).length > 0;

        return (
          <Stack flexDirection="row">
            {value}
            {isChangedRegistration && (
              <Chip
                size="sm"
                sx={{ alignSelf: 'center', marginLeft: 'auto' }}
                color="warning"
                onClick={() => onClickNotification(unionRegisterSeq)}
              >
                변동
              </Chip>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      hideable: false,
      rowSpanValueGetter: defaultRowSpanValueGetter,
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'shareType',
      headerName: '소유구분',
      groupable: true,
      groupingValueGetter: (value) =>
        getCommonCode('UNION_REGISTER_SHARE_TYPE', value)?.name || '',
      rowSpanValueGetter: defaultRowSpanValueGetter,
      valueGetter: (value) =>
        getCommonCode('UNION_REGISTER_SHARE_TYPE', value)?.name || '',
    },
    {
      field: 'isRepresentative',
      headerName: '대표자',
      type: 'boolean',
      rowSpanValueGetter: defaultRowSpanValueGetter,
    },
    {
      field: 'hasMultipleRight',
      headerName: '다물권',
      type: 'boolean',
      groupable: true,
      rowSpanValueGetter: defaultRowSpanValueGetter,
    },
    {
      field: 'hasVotingRight',
      headerName: '의결권',
      type: 'boolean',
      groupable: true,
      rowSpanValueGetter: defaultRowSpanValueGetter,
    },
    {
      field: 'salesCount',
      headerName: '분양권 개수',
      type: 'number',
      groupable: true,
      groupingValueGetter: (value) => (value > 0 && value) || '',
      rowSpanValueGetter: defaultRowSpanValueGetter,
      valueGetter: (value) => (value > 0 && value) || '',
    },
    {
      field: 'position',
      headerName: '직책',
      width: 50,
      minWidth: 50,
      groupable: true,
      groupingValueGetter: (value) =>
        getCommonCode('UNION_REGISTER_POSITION', value)?.name || '',
      rowSpanValueGetter: defaultRowSpanValueGetter,
      valueGetter: (value) =>
        getCommonCode('UNION_REGISTER_POSITION', value)?.name || '',
    },
    {
      field: 'positionDescription',
      headerName: '직책상세',
      groupable: true,
      rowSpanValueGetter: defaultRowSpanValueGetter,
    },
    {
      field: 'name',
      headerName: '이름',
      width: 160,
      minWidth: 160,
      rowSpanValueGetter: defaultRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.unionRegisterSeq &&
        (row?.name
          ? [row?.name?.name, row?.name?.nameClass].join('')
          : '이름없음'),
      renderCell: (params) => {
        const { unionRegisterSeq } = params.row;
        const { value } = params;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            href={`${unionRegisterSeq}/modify`}
          >
            {value}
          </Link>
        );
      },
    },
    {
      field: 'agent',
      headerName: '대리인',
      width: 160,
      minWidth: 160,
      valueGetter: (params: UnionRegisterAgentInfo, row) =>
        row?.agent?.unionRegisterAgentSeq &&
        [params?.name?.name, params?.name?.nameClass].join(''),
      rowSpanValueGetter: defaultRowSpanValueGetter,
      renderCell: (params) => {
        const { agent } = params.row;
        const { unionRegisterAgentSeq } = agent || {};

        return (
          agent &&
          unionRegisterAgentSeq && (
            <Link
              display="inline"
              width="100%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              onClick={() => onClickAgentName(unionRegisterAgentSeq)}
            >
              {params?.value}
            </Link>
          )
        );
      },
    },
    {
      field: 'ownerType',
      headerName: '개인/법인',
      groupable: true,
      groupingValueGetter: (value) =>
        getCommonCode('UNION_REGISTER_USER_DIVIDE', value)?.name || '',
      rowSpanValueGetter: defaultRowSpanValueGetter,
      valueGetter: (value) =>
        getCommonCode('UNION_REGISTER_USER_DIVIDE', value)?.name || '',
    },
    {
      field: 'birth',
      headerName: '생년월일',
      width: 130,
      minWidth: 130,
      rowSpanValueGetter: defaultRowSpanValueGetter,
      valueGetter: (_, row) =>
        formatBirth(row?.birth) ??
        (formatCompanyRegistrationNumber(row?.companyNo) || ''),
    },
    {
      field: 'gender',
      headerName: '성별',
      width: 50,
      minWidth: 50,
      groupable: true,
      groupingValueGetter: (value) =>
        value === 'M' ? '남자' : value === 'F' ? '여자' : '',
      rowSpanValueGetter: defaultRowSpanValueGetter,
      valueGetter: (value: string) =>
        value === 'M' ? '남자' : value === 'F' ? '여자' : '',
    },
    {
      field: 'mainPhone',
      headerName: '조합원 연락처',
      width: 130,
      minWidth: 130,
      rowSpanValueGetter: defaultRowSpanValueGetter,
      valueGetter: (_, row) =>
        (row?.mainPhone?.phoneNo && formatTelephone(row?.mainPhone?.phoneNo)) ||
        '',
    },
    {
      field: 'subPhone',
      headerName: '기타 연락처',
      width: 130,
      minWidth: 130,
      rowSpanValueGetter: defaultRowSpanValueGetter,
      valueGetter: (_, row) =>
        (row?.subPhones?.[0]?.phoneNo &&
          formatTelephone(row.subPhones[0].phoneNo)) ||
        '',
    },
    {
      field: 'registrationType',
      headerName: '등기구분',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.localAddress?.registrationType &&
        getCommonCode('REGISTRATION_TYPE', row.localAddress.registrationType)
          ?.name,
    },
    {
      field: 'localAddressDong',
      headerName: '법정동',
      groupable: true,
      groupingValueGetter: (_, row) => row?.localAddress?.dong,
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) => row?.localAddress?.dong,
    },
    {
      field: 'localAddressJibun',
      headerName: '지번',
      groupable: true,
      groupingValueGetter: (_, row) => row?.localAddress?.jibun,
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) => row?.localAddress?.jibun,
    },
    {
      field: 'localAddressBuilding',
      headerName: '건물명',
      groupable: true,
      groupingValueGetter: (_, row) => row?.localAddress?.building,
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) => row?.localAddress?.building,
    },
    {
      field: 'localAddressApartmentDong',
      headerName: '동',
      groupable: true,
      groupingValueGetter: (_, row) => row?.localAddress?.apartmentDong,
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) => row?.localAddress?.apartmentDong,
    },
    {
      field: 'localAddressApartmentFloor',
      headerName: '층',
      groupable: true,
      groupingValueGetter: (_, row) => row?.localAddress?.apartmentFloor,
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) => row?.localAddress?.apartmentFloor,
    },
    {
      field: 'localAddressApartmentHosu',
      headerName: '호수',
      groupable: true,
      groupingValueGetter: (_, row) => row?.localAddress?.apartmentHosu,
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) => row?.localAddress?.apartmentHosu,
    },
    {
      field: 'landOwnershipCategory',
      headerName: '지목',
      type: 'boolean',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.localAddress?.landOwnership?.landCategory &&
        getCommonCode(
          'LAND_CATEGORY',
          row.localAddress.landOwnership.landCategory,
        )?.name,
    },
    {
      field: 'landOwnershipPortion',
      headerName: '소유지분',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) => row?.localAddress?.landOwnership?.portion,
    },
    {
      field: 'landOwnershipTotalArea',
      headerName: '전체면적 ㎡',
      type: 'number',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) => row?.localAddress?.landOwnership?.totalArea,
    },
    {
      field: 'landOwnershipOwnedArea',
      headerName: '소유면적 ㎡',
      type: 'number',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) => row?.localAddress?.landOwnership?.ownedArea,
    },
    {
      field: 'landOwnershipIncorporatedArea',
      headerName: '편입면적 ㎡',
      type: 'number',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.localAddress?.landOwnership?.incorporatedArea,
    },
    {
      field: 'landOwnershipUtilization',
      headerName: '이용상황',
      type: 'boolean',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) => row?.localAddress?.landOwnership?.utilization,
    },
    {
      field: 'landOwnershipAcquiredAt',
      headerName: '취득일자',
      width: 110,
      minWidth: 110,
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.localAddress?.landOwnership?.acquiredAt &&
        formatDate(row.localAddress.landOwnership.acquiredAt),
    },
    {
      field: 'landOwnershipAcquisitionPeriod',
      headerName: '취득기간',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.localAddress?.landOwnership?.acquisitionPeriod,
    },
    {
      field: 'landOwnershipHasSurfaceRight',
      headerName: '지상권 여부',
      type: 'boolean',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.localAddress?.landOwnership?.hasSurfaceRight,
    },
    {
      field: 'buildingOwnershipIsPermitted',
      headerName: '허가유무',
      type: 'boolean',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.localAddress?.buildingOwnership?.isPermitted,
    },
    {
      field: 'buildingOwnershipBuildingName',
      headerName: '건물명',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.localAddress?.buildingOwnership?.buildingName,
    },
    {
      field: 'buildingOwnershipBuildingUsage',
      headerName: '용도',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.localAddress?.buildingOwnership?.buildingUsage &&
        getCommonCode(
          'BUILDING_USAGE',
          row.localAddress.buildingOwnership.buildingUsage,
        )?.name,
    },
    {
      field: 'buildingOwnershipBuildingStructure',
      headerName: '구조',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.localAddress?.buildingOwnership?.buildingUsage &&
        getCommonCode(
          'BUILDING_STRUCTURE',
          row.localAddress.buildingOwnership.buildingStructure,
        )?.name,
    },
    {
      field: 'buildingOwnershipFloor',
      headerName: '층별 지하/지상',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) => row?.localAddress?.buildingOwnership?.floor,
    },
    {
      field: 'buildingOwnershipUseApprovedAt',
      headerName: '사용승인일',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.localAddress?.buildingOwnership?.useApprovedAt &&
        formatDate(row.localAddress.buildingOwnership.useApprovedAt),
    },
    {
      field: 'buildingOwnershipPortion',
      headerName: '소유지분',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) => row?.localAddress?.buildingOwnership?.portion,
    },
    {
      field: 'buildingOwnershipTotalArea',
      headerName: '전체면적 ㎡',
      type: 'number',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) => row?.localAddress?.buildingOwnership?.totalArea,
    },
    {
      field: 'buildingOwnershipOwnedArea',
      headerName: '소유면적 ㎡',
      type: 'number',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) => row?.localAddress?.buildingOwnership?.ownedArea,
    },
    {
      field: 'buildingOwnershipAcquiredAt',
      headerName: '취득일자',
      width: 110,
      minWidth: 110,
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.localAddress?.buildingOwnership?.acquiredAt &&
        formatDate(row.localAddress.buildingOwnership.acquiredAt),
    },
    {
      field: 'buildingOwnershipAcquisitionPeriod',
      headerName: '취득기간',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row.localAddress?.buildingOwnership?.acquisitionPeriod,
    },
    {
      field: 'buildingOwnershipUnPermittedBuildingNo',
      headerName: '무허가 건물번호',
      width: 124,
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.localAddress?.buildingOwnership?.unPermittedBuildingNo,
    },
    {
      field: 'buildingOwnershipAssociatedJibun',
      headerName: '관련지번',
      rowSpanValueGetter: noneRowSpanValueGetter,
      valueGetter: (_, row) =>
        row?.localAddress?.buildingOwnership?.associatedJibun,
    },
    {
      field: 'realAddress',
      headerName: '실제 거주지',
      width: 230,
      minWidth: 230,
      rowSpanValueGetter: defaultRowSpanValueGetter,
      valueGetter: (value?: RealAddress) =>
        (value && [value?.address, value?.detailAddress].join(' ')) || '',
    },
    {
      field: 'postAddress',
      headerName: '우편물 수령지',
      width: 230,
      minWidth: 230,
      rowSpanValueGetter: defaultRowSpanValueGetter,
      valueGetter: (_, row) =>
        [row?.postAddress?.address, row?.postAddress?.detailAddress].join(' '),
    },
    {
      field: 'etcAddress',
      headerName: '기타 거주지',
      width: 230,
      minWidth: 230,
      rowSpanValueGetter: defaultRowSpanValueGetter,
    },
    {
      field: 'memo',
      headerName: '비고',
      width: 200,
    },
  ] as GridColDef<UnionRegisterRow>[];
