import { Grid, GridProps, Stack } from '@wooriga/design-system';
import { useCallback, useMemo } from 'react';

import ColorMapItem from 'components/ColorMap/ColorMapItem';
import ColorMapLabel from 'components/ColorMap/ColorMapLabel';

const MIN_RANGE_VALUE = 0.001;
const MAX_RANGE_VALUE = 99.999;

export interface ColorMapValue {
  id: string;
  title: string;
  value: number;
  label?: string;
}

export interface ColorMapProps {
  data: ColorMapValue[];
  colors: string[];
  orientation?: 'vertical' | 'horizontal';
  divide?: number;
  cellWidth?: number;
  cellHeight?: number;
  disabledColorLabel?: boolean;
  valueFormatter?: (value: number) => string;
  labelFormatter?: (label: string) => string;
}

const ColorMap = (props: ColorMapProps) => {
  const {
    data: defaultData,
    colors,
    valueFormatter,
    orientation = 'horizontal',
    divide = 3,
  } = props;

  /**
   * 숫자가 최소값부터 최대값이 존재했을 때, 그것을 n등분을 하고
   * 해당 값을 파라미터로 넘겼을 때, 어느 구간에 속한지 확인할 수 있는 함수
   *
   * @param value 확인하고 있는 값
   * @param n 몇 등분할 값
   * @param minValue 최솟값
   * @param maxValue 최댓값
   * @returns value가 속한 구간을 배열 index로 반환
   */
  const findRangeIndex = (
    value: number,
    n: number,
    minValue = MIN_RANGE_VALUE,
    maxValue = MAX_RANGE_VALUE,
  ) => {
    const step = (maxValue - minValue) / n;
    const sections = Array.from({ length: n }, (_, i) =>
      Math.round(minValue + (i + 1) * step),
    );

    for (let index = 0; index < sections.length; index++) {
      if (value < sections[index]) {
        return index;
      }
    }

    return sections.length - 1;
  };

  /**
   * 0 ~ 100% 중 하나의 값을 주면
   * 해당 값이 연속된 색상들 중 어느 색상인지 추출하는 함수
   *
   * 0%는 무조건 0번째 값으로 줘야 한다
   * 100% 무조건 연속된 색상의 마지막(length - 1)번째 값을 줘야 한다
   * 0 초과 100 미만은 findRangeIndex에 의해서 색상이 결정된다
   */
  const matchContinuousColor = useCallback(
    (value: number) => {
      const targetRangeCount = colors.length - 2; // 맨 앞과 뒤에 색상을 제외한 총 색상 개수

      if (value === 0) {
        return colors[0];
      }

      if (value === 100) {
        return colors[colors.length - 1];
      }

      const colorIndex = findRangeIndex(value, targetRangeCount);

      return colors[colorIndex + 1]; // 0번째에 대한 범위 대응을 위한 +1 적용
    },
    [colors],
  );

  const directions = useMemo(
    () => ({
      container:
        orientation === 'horizontal'
          ? 'column'
          : ('row' as GridProps['direction']),
      item:
        orientation === 'horizontal'
          ? 'row'
          : ('column' as GridProps['direction']),
    }),
    [orientation],
  );

  const data = useMemo(
    () =>
      defaultData.map((item) => ({
        ...item,
        color: matchContinuousColor(item.value),
      })),
    [defaultData, matchContinuousColor],
  );

  return (
    <Stack gap={7.75}>
      <ColorMapLabel colors={colors} />

      <Grid
        container
        direction={directions.container}
        spacing={1}
        sx={{ overflowX: 'auto', flexGrow: 1 }}
      >
        {Array.from({ length: Math.ceil(data.length / divide) }).map(
          (_, index) => (
            <Grid key={`color_map_column_${index}`}>
              <Stack direction={directions.item} gap={1}>
                {data
                  .slice(index * divide, (index + 1) * divide)
                  .map(({ id, title, value, label, color }) => (
                    <ColorMapItem
                      key={`color_map_item_${id}`}
                      color={color}
                      title={title}
                      value={valueFormatter?.(value) ?? String(value)}
                      label={label}
                    />
                  ))}
              </Stack>
            </Grid>
          ),
        )}
      </Grid>
    </Stack>
  );
};

export default ColorMap;
