export const ELECTRONIC_CONSENT_TEMPLATES_JSON = [
  {
    no: 1,
    templateSeq: 1,
    name: 'TEST 동의서',
    signatureUsage: 'OPTIONAL', // REQUIRED, DISABLED, OPTIONAL
    originFileName: 'aaa.json',
    fileUrl: 'https://content.dev.wooriga.kr/consent/template/faker.json',
    createdAt: '2024-12-17T15:10:00',
  },
];
