import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import {
  ElectronicConsent,
  ElectronicConsentRecipient,
  ElectronicConsentStatisticArea,
  ElectronicConsentStatisticDong,
  ProgressStatus,
} from 'apis/types/consent';
import { ApiError, ApiResponseData } from 'types/api';

/**
 * 전자 동의서 관련 APIs
 *
 * HTTP Method: GET
 */

// 전자동의서 목록 조회
type UseReadElectronicConsentsResult = UseQueryResult<
  ApiResponseData<ElectronicConsentsResponse, true>,
  ApiError
>;

export type ElectronicConsentsResponse = ElectronicConsent[];
export interface ElectronicConsentsParams {
  searchUnionSeq: number;
  searchTitle: string;
}

export const useReadElectronicConsents = (
  params: ElectronicConsentsParams,
): UseReadElectronicConsentsResult => {
  return useQuery({
    queryKey: [`/v2/electronic-consents`, params],
  });
};

// 전자동의서 상세 조회
type UseReadElectronicConsentResult = UseQueryResult<
  ApiResponseData<ElectronicConsentResponse>,
  ApiError
>;

export type ElectronicConsentResponse = ElectronicConsent;
export interface ElectronicConsentParams {
  consentSeq: number;
}

export const useReadElectronicConsent = ({
  consentSeq,
  ...restParams
}: ElectronicConsentParams): UseReadElectronicConsentResult => {
  return useQuery({
    queryKey: [`/v2/electronic-consents/${consentSeq}`, restParams],
  });
};

// 전자동의서 대상자 목록 조회
type UseReadElectronicConsentRecipientsResult = UseQueryResult<
  ApiResponseData<ElectronicConsentRecipientResponse, true>,
  ApiError
>;

export type ElectronicConsentRecipientResponse = ElectronicConsentRecipient[];
export interface ElectronicConsentRecipientsParams {
  consentSeq: number;
  searchRecipientName?: string;
  searchShareType?: string;
}

export const useReadElectronicConsentRecipients = ({
  consentSeq,
  ...restParams
}: ElectronicConsentRecipientsParams): UseReadElectronicConsentRecipientsResult => {
  return useQuery({
    queryKey: [`/v2/electronic-consents/${consentSeq}/recipients`, restParams],
  });
};

// 전자동의서 면적별 동의율 조회
type UseReadElectronicConsentStatisticsAreaResult = UseQueryResult<
  ApiResponseData<ElectronicConsentStatisticsAreaResponse>,
  ApiError
>;

export type ElectronicConsentStatisticsAreaResponse =
  ElectronicConsentStatisticArea;
export interface ElectronicConsentStatisticsAreaParams {
  consentSeq: number;
  isEnabled?: boolean;
}

export const useReadElectronicConsentStatisticsArea = ({
  consentSeq,
  isEnabled,
}: ElectronicConsentStatisticsAreaParams): UseReadElectronicConsentStatisticsAreaResult => {
  return useQuery({
    queryKey: [`/v2/electronic-consents/${consentSeq}/statistics/area`],
    enabled: isEnabled,
  });
};

// 전자동의서 동별 동의율 조회
type UseReadElectronicConsentStatisticsDongResult = UseQueryResult<
  ApiResponseData<ElectronicConsentStatisticsDongResponse>,
  ApiError
>;

export type ElectronicConsentStatisticsDongResponse =
  ElectronicConsentStatisticDong[];
export interface ElectronicConsentStatisticsDongParams {
  consentSeq: number;
  isEnabled?: boolean;
}

export const useReadElectronicConsentStatisticsDong = ({
  consentSeq,
  isEnabled,
}: ElectronicConsentStatisticsDongParams): UseReadElectronicConsentStatisticsDongResult => {
  return useQuery({
    queryKey: [`/v2/electronic-consents/${consentSeq}/statistics/dong`],
    enabled: isEnabled,
  });
};

/**
 * 전자 동의서 관련 APIs
 *
 * HTTP Method: POST
 */

// 전자동의서 생성
type UseCreateElectronicConsentResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentCreateBody
>;
export interface ElectronicConsentCreateBody {
  templateSeq: number;
  unionSeq: number;
  unionRegisterSeqs: number[];
  title: string;
  content: string;
  isSignatureRequired: boolean;
  isActiveCondition: boolean;
  consentCondition: number;
  startedAt: string;
  endedAt: string | null;
  linkCode: string;
}

export const useCreateElectronicConsent =
  (): UseCreateElectronicConsentResult => {
    return useMutation({
      mutationFn: async (body) => {
        const { data } = await axiosInstance.post(
          `/v2/electronic-consents`,
          body,
        );

        return data;
      },
    });
  };

// 전자동의서 진행 상태 변경
type UseCreateElectronicConsentProgressStatusResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentProgressStatusParams & ElectronicConsentProgressStatusBody
>;

export interface ElectronicConsentProgressStatusParams {
  consentSeq: number;
}

export interface ElectronicConsentProgressStatusBody {
  progressStatus: ProgressStatus;
}

export const useCreateElectronicConsentProgressStatus =
  (): UseCreateElectronicConsentProgressStatusResult => {
    return useMutation({
      mutationFn: async ({ consentSeq, ...body }) => {
        const { data } = await axiosInstance.post(
          `/v2/electronic-consents/${consentSeq}/progress-status`,
          body,
        );

        return data;
      },
    });
  };

// 전자동의서 철회
type UseCreateElectronicConsentWithdrawalResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentWithdrawalParams
>;

export interface ElectronicConsentWithdrawalParams {
  consentSeq: number;
  recipientSeq: number;
}

export const useCreateElectronicConsentWithdrawal =
  (): UseCreateElectronicConsentWithdrawalResult => {
    return useMutation({
      mutationFn: async ({ consentSeq, recipientSeq }) => {
        const { data } = await axiosInstance.post(
          `/v2/electronic-consents/${consentSeq}/recipients/${recipientSeq}/withdraw`,
        );

        return data;
      },
    });
  };

// 전자동의서 철회 취소
type UseCreateElectronicConsentWithdrawalCancelResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentWithdrawalParams
>;

export interface ElectronicConsentWithdrawalCancelParams {
  consentSeq: number;
  recipientSeq: number;
}

export const useCreateElectronicConsentWithdrawalCancel =
  (): UseCreateElectronicConsentWithdrawalCancelResult => {
    return useMutation({
      mutationFn: async ({ consentSeq, recipientSeq }) => {
        const { data } = await axiosInstance.post(
          `/v2/electronic-consents/${consentSeq}/recipients/${recipientSeq}/withdraw/cancel`,
        );

        return data;
      },
    });
  };

// 전자동의서 일괄 재발송
type UseCreateElectronicConsentResendResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentSendParams & ElectronicConsentSendBody
>;

export interface ElectronicConsentSendParams {
  consentSeq: number;
}

export interface ElectronicConsentSendBody {
  recipientSeqs: number[];
}

export const useCreateElectronicConsentResend =
  (): UseCreateElectronicConsentResendResult => {
    return useMutation({
      mutationFn: async ({ consentSeq, ...body }) => {
        const { data } = await axiosInstance.post(
          `/v2/electronic-consents/${consentSeq}/send`,
          body,
        );

        return data;
      },
    });
  };

/**
 * 전자 동의서 관련 APIs
 *
 * HTTP Method: PUT
 */

// 전자동의서 수정
type UseUpdateElectronicConsentResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentUpdateParams &
    Omit<ElectronicConsentCreateBody, 'unionRegisterSeqs' | 'linkCode'>
>;

export interface ElectronicConsentUpdateParams {
  consentSeq: number;
}

export const useUpdateElectronicConsent =
  (): UseUpdateElectronicConsentResult => {
    return useMutation({
      mutationFn: async ({ consentSeq, ...body }) => {
        const { data } = await axiosInstance.put(
          `/v2/electronic-consents/${consentSeq}`,
          body,
        );

        return data;
      },
    });
  };

// 전자동의서 대상자 추가
type UseUpdateElectronicConsentRecipientsResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentRecipientsUpdateParams &
    ElectronicConsentRecipientsUpdateBody
>;

export interface ElectronicConsentRecipientsUpdateParams {
  consentSeq: number;
}

export interface ElectronicConsentRecipientsUpdateBody {
  unionSeq: number;
  unionRegisterSeqs: number[];
}

export const useUpdateElectronicConsentRecipients =
  (): UseUpdateElectronicConsentRecipientsResult => {
    return useMutation({
      mutationFn: async ({ consentSeq, ...body }) => {
        const { data } = await axiosInstance.post(
          `/v2/electronic-consents/${consentSeq}/recipients`,
          body,
        );

        return data;
      },
    });
  };

/**
 * 전자 동의서 관련 DELETE APIs
 */

// 전자동의서 대상자 제외
type UseDeleteElectronicConsentRecipientResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentRecipientDeleteParams
>;

export interface ElectronicConsentRecipientDeleteParams {
  consentSeq: number;
  recipientSeq: number;
}

export const useDeleteElectronicConsentRecipient =
  (): UseDeleteElectronicConsentRecipientResult => {
    return useMutation({
      mutationFn: async ({ consentSeq, recipientSeq }) => {
        const { data } = await axiosInstance.delete(
          `/v2/electronic-consents/${consentSeq}/recipients/${recipientSeq}`,
        );

        return data;
      },
    });
  };
